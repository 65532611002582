import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import ProjectListMain from 'pages/apps/project-management/ProjectListMain';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import App from 'App';
import SplitSignIn from 'pages/pages/authentication/split/SignIn';
import SplitSignUp from 'pages/pages/authentication/split/SignUp';
import SplitSignOut from 'pages/pages/authentication/split/SignOut';
import SplitForgotPassword from 'pages/pages/authentication/split/ForgotPassword';
import SplitResetPassword from 'pages/pages/authentication/split/ResetPassword';
import SplitLockScreen from 'pages/pages/authentication/split/LockScreen';
import SplitTwoFA from 'pages/pages/authentication/split/TwoFA';
import { lazy } from 'react';
import { PrivateRoute } from 'PrivateRoute';
import CreateProject from 'pages/apps/project-management/CreateProject';

import AdminEntityList from 'admin/entity/AdminEntityList';
import AdminEntityListView from 'admin/entity/AdminEntityListView';
import { PrivateRouteAdmin } from 'PrivateRouteAdmin';
import AdminUsersList from 'admin/users/AdminUsersList';
import AdminUserView from 'admin/users/AdminUserView';
import AdminProjectsList from 'admin/projects/AdminProjectsList';
import AdminMetaListView from 'admin/meta/AdminMetaListView';
import AdminProjectCategoryList from 'admin/project-category/AdminProjectCategoryList';
import AdminDesignationList from 'admin/designation/AdminDesignationList';
import MeetinglocationcategoryList from 'admin/meetinglocationcategory/MeetinglocationcategoryList';
import MeetingLocationList from 'admin/meetinglocation/MeetingLocationList';
import MeetingCategoryList from 'admin/meetingcategory/MeetingCategoryList';
import AdminCreateProject from 'admin/projects/AdminCreateProject';
// import AdminProjectPermissions from 'admin/projects/AdminProjectPermissions';
import AdminUpdateProject from 'admin/projects/AdminUpdateProject';
import ProjectSettings from 'pages/apps/project-management/view/ProjectSettings';
import ProjectOverView from 'pages/apps/project-management/view/ProjectOverView';
import ProjectInfo from 'pages/apps/project-management/view/ProjectInfo';
import HomeSpider from 'pages/apps/spr-dashboard/HomeSpider';
import AdminHome from 'admin/adminhome/AdminHome';
import AdminProjectView from 'admin/projects/AdminProjectView';
import ProjectListMainScrollTest from 'pages/apps/project-management/ProjectListMainScrollTest';
import ProjectInfoAdmin from 'pages/apps/project-management/view/ProjectInfoAdmin';
import ProjectActionPoint from 'pages/apps/project-management/view/action-point/ProjectActionPoint';
import ProjectInfoSection from 'pages/apps/project-management/view/ProjectInfoSection';
import UpdatedProject from 'pages/apps/project-management/view/UpdatedProject';
import Notes from 'pages/apps/project-management/view/notes/Notes';
import ProjectStory from 'pages/apps/project-management/view/story/ProjectStory';
import AllActionPoints from 'pages/apps/all-action-points/AllActionPoints';
import AdminPhaseList from 'admin/phase/AdminPhaseList';
import AdminPriorityList from 'admin/priority/AdminPriorityList';
import UserProfile from 'pages/user/UserProfile';
import AdminWorkSpaceSetting from 'pages/user/AdminWorkSpaceSetting';
import BlockEditor from 'admin/TestEditor/BlockEditor';
import AuthenticateInvitation from 'pages/pages/authentication/split/AuthenticateInvitation';
import Counter from 'admin/TestEditor/Counter';
import CkEditor from 'admin/TestEditor/CkEditor';



const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: '/',
            element: <PrivateRoute element={<HomeSpider />} />
          },
          
          {
            path: '/:workspace',
            element: <PrivateRoute element={<HomeSpider />} />
          },
          {
            path: '/',
            children: [
              {
                path: '/:workspace/admin/ckeditor',
                element: <PrivateRoute element={<CkEditor />} />
              },
              {
                path: '/:workspace/admin/counter',
                element: <PrivateRoute element={<Counter />} />
              },
              {
                path: '/:workspace/admin/block-editor',
                element: <PrivateRoute element={<BlockEditor />} />
              },
              {
                path: '/admin/all-workspace/list',
                element: <PrivateRoute element={<AdminWorkSpaceSetting />} />
              },
              {
                path: '/:workspace/user/profile',
                element: <PrivateRoute element={<UserProfile />} />
                // element: <PrivateRoute element= {<ProjectListMainScrollTest />} />
              },
              {
                path: '/:workspace/project/:list',
                element: <PrivateRoute element={<ProjectListMain />} />
                // element: <PrivateRoute element= {<ProjectListMainScrollTest />} />
              },
              {
                path: '/:workspace/project/create-project',
                element: <PrivateRoute element={<CreateProject />} />,
              },

              {
                path: '/:workspace/project/:alias/project-update',
                element: <PrivateRoute element={<UpdatedProject />} />
              },
              {

                path: '/:workspace/project/:alias/overview',
                element: <PrivateRoute element={<ProjectOverView />} />
              },

              {

                path: '/:workspace/project/:alias/setting',
                element: <PrivateRoute element={<ProjectSettings />} />
              },
              {

                path: '/:workspace/project/:alias/project-info',
                element: <PrivateRoute element={<ProjectInfo />} />
              },
              {

                path: '/:workspace/project/:alias/project-info/project-info-admin',
                element: <PrivateRoute element={<ProjectInfoAdmin />} />
              },

              {

                path: '/:workspace/project/:alias/project-info/project-info-admin/projectinfo-section',
                element: <PrivateRoute element={<ProjectInfoSection />} />
              },
              {

                path: '/:workspace/project/:alias/action-point',
                element: <PrivateRoute element={<ProjectActionPoint />} />
              },
              {

                path: '/:workspace/all-action-point/list',
                element: <PrivateRoute element={<AllActionPoints />} />
              },
              {

                path: '/:workspace/project/:alias/notes',
                element: <PrivateRoute element={<Notes />} />
              },
              {

                path: '/:workspace/project/:alias/project-story',
                element: <PrivateRoute element={<ProjectStory />} />
              }

            ]
          },
          {
            path: '/:workspace/admin',
            children: [
              {
                path: 'home',
                element: <PrivateRouteAdmin element={<AdminHome />} />
              },
              {
                path: 'entity/list',
                element: <PrivateRouteAdmin element={<AdminEntityList />} />
              },
              {
                path: 'entity/:alias/view',
                element: <PrivateRouteAdmin element={<AdminEntityListView />} />
              },
              {
                path: 'users/list',
                element: <PrivateRouteAdmin element={<AdminUsersList />} />
              },
              {
                path: 'users/:id/view',
                element: <PrivateRouteAdmin element={<AdminUserView />} />
              },
              {
                path: 'project/list',
                element: <PrivateRouteAdmin element={<AdminProjectsList />} />
              },
              {
                path: 'project/:alias/view',
                element: <PrivateRouteAdmin element={<AdminProjectView />} />
              },
              {
                path: 'create-project',
                element: <PrivateRouteAdmin element={<AdminCreateProject />} />
              },
              // {
              //   path: 'project-permissions/:alias',
              //   element:  <PrivateRouteAdmin element= { <AdminProjectPermissions/>} />
              // },
              {
                path: 'project/:alias/update-project',
                element: <PrivateRouteAdmin element={<AdminUpdateProject />} />
              },
              {
                path: 'meta/list',
                element: <PrivateRouteAdmin element={<AdminMetaListView />} />
              },
              {
                path: 'master/project-category/list',
                element: <PrivateRouteAdmin element={<AdminProjectCategoryList />} />
              },
              {
                path: 'masters/designation/list',
                element: <PrivateRouteAdmin element={<AdminDesignationList />} />

              },
              {
                path: 'masters/meetinglocationcategory/list',
                element: <PrivateRouteAdmin element={<MeetinglocationcategoryList />} />

              },
              {
                path: 'masters/meetinglocation/list',
                element: <PrivateRouteAdmin element={<MeetingLocationList />} />

              },
              {
                path: 'masters/meetingcategory/list',
                element: <PrivateRouteAdmin element={<MeetingCategoryList />} />

              },
              {
                path: 'masters/phases/list',
                element: <PrivateRouteAdmin element={<AdminPhaseList />} />

              },
              {
                path: 'masters/priority/list',
                element: <PrivateRouteAdmin element={<AdminPriorityList />} />

              }
            ]
          },
          // {
          //   path: '/',
          //   children: [      
          //     {
          //       path: 'all-workspace/list',
          //       element: <PrivateRouteAdmin element={<AdminWorkSpaceSetting />} />

          //     },
          //   ]
          // }
        ]
      },

      {

        path: '/',
        children: [
          {
            path: 'sign-in',
            element: <SplitSignIn />
          },
          {
            path: 'sign-up',
            element: <SplitSignUp />
          },
          {
            path: 'sign-out',
            element: <SplitSignOut />
          },
          {
            path: 'forgot-password',
            element: <SplitForgotPassword />
          },
          {
            path: 'reset-password',
            element: <SplitResetPassword />
          },
          {
            path: 'invitation',
            element: <AuthenticateInvitation />
          },
          {
            path: 'lock-screen',
            element: <SplitLockScreen />
          },
          {
            path: '2FA',
            element: <SplitTwoFA />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
