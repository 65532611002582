import { faArrowDownWideShort} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Unicon from "components/base/Unicon";
import { UilCheck } from "@iconscout/react-unicons";

interface FilterProps {
  handleDropDown: (option: string) => void; // Define the type of handleDropDown prop
  value: string;
}


function Filtersort({ handleDropDown, value }: FilterProps) {


  const handleDropdownSelect = (option: string) => {
    handleDropDown(option)
  };

  const handleSelectedOptionClick = () => {
    const toggleButton = document.getElementById("dropdown-toggle");
    if (toggleButton) {
      toggleButton.click();
    }
  };

  const showText = (value: string) => {
    switch (value) {
      case "when_ascending":
        return "When Ascending";
      case "title":
        return "A to Z";
      case "-title":
        return "Z to A";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="d-flex gap-1 align-items-center me-3 filter_select">
        <Dropdown>
          <Dropdown.Toggle
            variant="phoenix-secondary"
            className="d-flex align-items-center  dropdown-caret-none "
            size="lg"
            id="dropdown-toggle"
            style={{ background: "transparent", border: "0", padding: "0" }}
          >
            <FontAwesomeIcon icon={faArrowDownWideShort} />
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-2" style={{ zIndex: 9999 }}>
            <Dropdown.Item
              onClick={() => handleDropdownSelect("when_ascending")} style={{fontSize:"12.8px"}}
            >
              When Ascending {value === "when_ascending" ? <Unicon icon={UilCheck} size={20} id="mark-as-complete" /> : ""}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleDropdownSelect("title")} style={{fontSize:"12.8px"}}
            >
              A to Z {value === "title" ? <Unicon icon={UilCheck} size={20} id="mark-as-complete" /> : ""}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleDropdownSelect("-title")} style={{fontSize:"12.8px"}}
            >
              Z to A {value === "-title" ? <Unicon icon={UilCheck} size={20} id="mark-as-complete" /> : ""}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="lableSelect">
          <label onClick={handleSelectedOptionClick}>{showText(value)}</label>
        </div>
      </div>
    </>
  );
}

export default Filtersort;
