import { forwardRef, useRef } from 'react';



export default function Counter() {
    // let ref = useRef(0);

      const inputRef = useRef(null)

    function handleClick() {
        // ref.current = ref.current + 1;
        // alert('You clicked ' + ref.current + ' times!');

        inputRef.current.focus();
    }

    // function Video() {
    //     const playerRef = useRef(null);
    //     if (playerRef.current === null) {
    //         playerRef.current = new VideoPlayer();
    //     }
    // }

    return (
        <>
            {/* <span>{ref.current}</span> */}
            <input ref={inputRef} />
            <input  />
            <button onClick={handleClick}>
                Click me!
            </button>
        </>

    );
}
// useRef
// useRef is a React Hook that lets you reference a value that’s not needed for rendering.
// Usage
// 1). Referencing a value with a ref

// Changing a ref does not trigger a re-render. This means refs are perfect for storing information that doesn’t affect the visual output of your component. For example, if you need to store an interval ID and retrieve it later, you can put it in a ref

// Manipulating the DOM with a ref
// Avoiding recreating the ref contents

//const ref = useRef(initialValue)
//If you show {ref.current} in the JSX, the number won’t update on click. 
//This is because setting ref.current does not trigger a re-render. 
//Information that’s used for rendering should be state instead.