import { faGithub, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import React from 'react';

const AuthSocialButtons = ({ title }: { title: string }) => {
  return (
    <div className="d-flex  gap-2">
      <Button
        variant="phoenix-secondary"
        className="w-100 mb-3"
        startIcon={
          <FontAwesomeIcon icon={faGoogle} className="text-danger me-2 fs-9" />
        }
      >
        Google
      </Button>
      <Button
        variant="phoenix-secondary"
        className="w-100 mb-3"
        startIcon={
          <FontAwesomeIcon icon={faGithub} className="text-primary me-2 fs-9" />
        }
      >
        GitHub
      </Button>
    </div>
  );
};

export default AuthSocialButtons;
