
import Constants from 'common/Constants';
import PageBreadcrumb, { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import {  NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import { Col, Nav, Row, Tab, Table } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { NavPositionVariant, NavTopShapeVariant } from 'config';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import Unicon from 'components/base/Unicon';
import { UilToggleOff, UilToggleOn } from '@iconscout/react-unicons';
import { getAdminPageBreadCrumbs } from 'services/getAdminPageBreadCrumbs';
import { ToastContainer, toast } from 'react-toastify';
import { handleCutomError } from 'services/handleCutomError';


// Define the PriorityItem interface
interface PriorityItem {
  id: number;
  title: string;
  color: string;
  orderby: number;
  status: number;
  created_by: number;
  updated_by: number;
  created_at: number;
  updated_at: number;
}


//Define the Roles interface

interface Roles {
  id: number;
  title: string;
  alias: string;
  status: number;
}
const AdminMetaListView = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("AdminMetaList")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth()
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [priority, setPriority] = useState<PriorityItem[] | null>(null);
  const [allMetas, setAllMetas] = useState<Roles[] | null>(null);




  const {
    config: { theme, navbarPosition },
    setConfig
  } = useAppContext();

  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, workspace]);

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = 'slim';
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });

  }, [])

  useEffect(() => {

    const fetchData = async () => {
      try {
        const [projStatusResponse, allMetas] = await Promise.all([
          apiCall({
            url: 'meta/project-status',
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              'workspace': workSpaceTkn
            },
          }),
          apiCall({
            url: 'meta/all-meta/list',
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              'workspace': workSpaceTkn
            },
          }),
        ]);

        if (projStatusResponse?.status === 200) {
          // Handle the response data if needed
          setPriority(projStatusResponse.data.Projectstatus)
        }
        if (allMetas?.status === 200) {
          // Handle the response data if needed
          setAllMetas(allMetas.data.roles)
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      }
    };

    // Run the effect when the user logs in or the page is refreshed

    fetchData();

  }, []);

  useSettingsMountEffect({
    showSettingPanelButton: false
  });


  const getStatusIcon = (status: number) => {
    if (status === 1) return <Unicon icon={UilToggleOn} size={21} color="" fill="#8FC644" />
    else if (status === 0) return <Unicon icon={UilToggleOff} size={21} color="" fill="#6E7891" />
    else return <div></div>
  }

  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <div className="d-flex flex-wrap mb-1  justify-content-between">
        <h2 className="mb-3">
          <span className="me-3">{Constants.meta}</span>{' '}
        </h2>

      </div>
      <Row>
        <Col xl={12} xxl={12} className="mb-1">
          <Tab.Container defaultActiveKey="projectStatus">
            <Nav variant="underline" className="mb-3">
              <Nav.Item>
                <Nav.Link eventKey="projectStatus">Project Status</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="roles">Roles</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="userStatus">User Status</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="projectStatus">
                <PhoenixDocCard className='p-4 mt-2' >

                  <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive>
                    <thead>
                      <tr id={'a1'} >
                        <th style={{ width: '1%', maxWidth: '10px', padding: "16px 0px" }}>#</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.title}</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.status}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {priority && priority.map((item: PriorityItem, index: number) => {

                        return (
                          <tr key={item?.id || index}>
                            <td>{(index + 1)}</td>
                            <td>
                              <p className="text-decoration-none fw-semi-bold fs-8 mb-0">{`${item?.title}`}</p>
                              {/* <Link to="#" className="text-decoration-none fw-bold fs-8"></Link> */}
                            </td>

                            <td>
                              {/* {getStatusIcon(item?.status)} */}
                              {item?.id}
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </Table>

                </PhoenixDocCard>
              </Tab.Pane>
              <Tab.Pane eventKey="roles">
                <PhoenixDocCard className='p-4 mt-2' >

                  <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive>
                    <thead>
                      <tr id={'a1'} >
                        <th style={{ width: '1%', maxWidth: '20px', padding: "16px 0px" }}>#</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.title}</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.status}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allMetas && allMetas.map((item: Roles, index: number) => {

                        return (
                          <tr key={item?.id || index}>
                            <td>{(index + 1)}</td>
                            <td>
                              <p className="text-decoration-none fw-semi-bold fs-8 mb-0">{`${item?.title}`}</p>
                              {/* <Link to="#" className="text-decoration-none fw-bold fs-8"></Link> */}
                            </td>

                            <td>
                              {/* {getStatusIcon(item?.status)} */}
                              {item?.id}
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </Table>
                </PhoenixDocCard>
              </Tab.Pane>
              <Tab.Pane eventKey="userStatus">
                <PhoenixDocCard className='p-4 mt-2' >

                  <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive>
                    <thead>
                      <tr id={'a1'} >
                        <th style={{ width: '1%', maxWidth: '20px', padding: "16px 0px" }}>#</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.title}</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.status}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allMetas && allMetas.map((item: Roles, index: number) => {

                        return (
                          <tr key={item?.id || index}>
                            <td>{(index + 1)}</td>
                            <td>
                              <p className="text-decoration-none fw-semi-bold fs-8 mb-0">{`${item?.title}`}</p>
                              {/* <Link to="#" className="text-decoration-none fw-bold fs-8"></Link> */}
                            </td>

                            <td>
                              {/* {getStatusIcon(item?.status)} */}
                              {item?.id}
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </Table>
                </PhoenixDocCard>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>

      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminMetaListView;
