import { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Paragraph from "@editorjs/paragraph";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Delimiter from "@editorjs/delimiter";
import Checklist from "@editorjs/checklist";
import LinkTool from '@editorjs/link';
import SimpleImage from "@editorjs/simple-image";
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import CodeTool from '@editorjs/code';
import InlineCode from '@editorjs/inline-code';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import RawTool from '@editorjs/raw';
import Alert from 'editorjs-alert';
import NestedList from '@editorjs/nested-list';
import Underline from '@editorjs/underline';
import { Row, Col, Container } from 'react-bootstrap';
//https://github.com/editor-js/awesome-editorjs?tab=readme-ov-file

const DEFAULT_INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: [
    {
      type: "header",
      data: {
        text: "This is my awesome editor!",
        level: 1,
      },
    },
  ],
};

const BlockEditor = () => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = new EditorJS({
        holder: "editorjs",
        // readOnly: true,
        autofocus: true,
        data: DEFAULT_INITIAL_DATA,
        tools: {
          header: Header,
          paragraph: Paragraph,
          quote: Quote,
          warning: {
            class: Warning,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+W',
            config: {
              titlePlaceholder: 'Title',
              messagePlaceholder: 'Message',
            },
          },
          delimiter: Delimiter,
          linkTool: {
            class: LinkTool,
            config: {
              endpoint: 'http://localhost:8008/fetchUrl', // Your backend endpoint for url data fetching,
            }
          },

          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered'
            }
          },
          list: {
            class: NestedList,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered'
            },
          },

          Marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M',
          },
          code: CodeTool,
          inlineCode: {
            class: InlineCode,
            shortcut: 'CMD+SHIFT+M',
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true,
                coub: true
              }
            }
          },
          raw: RawTool,
          table: Table,
          alert: Alert,

          underline: Underline,
          image: SimpleImage,
          // You can add more tools here as needed
        },
        onChange: async () => {
          const content = await editorRef.current.save();
          console.log("Content saved:", content);
        },
      });
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, []);

  return (
    <Container>

      <Row className="justify-content-md-center">
        <Col className="" lg={4}></Col>
        <Col lg={4}>
          <div id="editorjs" style={{ border: "1px solid #cccccc" }}></div>
        </Col>
        <Col lg={4}> </Col>
      </Row>
    </Container>
  )

};

export default BlockEditor;
