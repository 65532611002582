import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

export interface HelpPanelConfig {

    openHelpPanel: boolean;
    helpPage: string;
    
  }

  interface HelpPanelContextInterFace {
    helpPanelConfig: HelpPanelConfig;
    setHelpPanelConfig: Dispatch<SetStateAction<HelpPanelConfig>>;
  }

  export const HelpPanelContext = createContext(
    {} as HelpPanelContextInterFace
  );

  const HelpPanelProvider = ({ children }: PropsWithChildren) => {
    const [helpPanelConfig, setHelpPanelConfig] = useState<HelpPanelConfig>({
        openHelpPanel: false,
        helpPage:''
    })



    
    return (
        <HelpPanelContext.Provider
          value={{
            helpPanelConfig,
            setHelpPanelConfig
          }}
        >
          {children}
        </HelpPanelContext.Provider>
      );
  }

  export default HelpPanelProvider;

  export const useHelpPanelContext = () => useContext(HelpPanelContext);