import axios from 'axios';
import Constants from 'common/Constants';
import { timeStampToDbDate } from 'common/timeStampToDate';
import Button from 'components/base/Button';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { useAuth } from 'providers/AuthContext';
import { useMaster } from 'providers/MasterContext';
import { useAppContext } from "providers/AppProvider";

import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate, NavigateFunction,Link } from 'react-router-dom';
import Unicon from 'components/base/Unicon';

import { UilCube} from '@iconscout/react-unicons';
interface FormData {
  title: string;
  start_date: string;
  end_date: string;
  category: string;
  phase: string;
  donar: string;
  description: string;
  tags: string[];
}

interface formError {
  start_date_err: string;
  end_date_err: string;
}

type ReactSelectOption = {
  value: number;
  label: string;
};

const ProjectInfoAdmin: React.FC = () => {
  const { userTkn } = useAuth();
  const { tags, projCategory, phases } = useMaster();
  const [tagsData, setTagsData] = useState<ReactSelectOption[] | undefined>();
  const [validated, setValidated] = useState<boolean>(false);
  
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type

  useSettingsMountEffect({
    showSettingPanelButton: false
  });


  const [formData, setFormData] = useState<FormData>({
    title: '',
    start_date: '',
    end_date: '',
    category: '',
    phase: '',
    donar: '',
    description: '',
    tags: [],
  });
  const [error, setError] = useState<formError>({
    start_date_err: "",
    end_date_err: ""
  });
  const [submitError, setSubmitError] = useState<any>("")
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | Date[], fieldName: string) => {
    if (Array.isArray(e)) {
      // Handle Date[] case (coming from DatePicker)
      const newDate = timeStampToDbDate(e[0]);
      
      
      if (fieldName === 'end_date' && new Date(newDate) < new Date(formData.start_date)) {
        // If end date is earlier than start date, set a custom error
        // You can also display the error message to the user
        console.error('End date must be greater than or equal to start date');
        setError((prevError) => ({
          ...prevError,
          end_date_err: "End date must be greater than or equal to start date",
        }));
        return;
      } else {
        
        setFormData({
          ...formData,
          [fieldName]: newDate,  // Adjust accordingly 
        });
        setError((prevError) => ({
          ...prevError,
          start_date_err: "",
          end_date_err: "", // Clear the error when the input is valid
        }));

      }

    } else {
      // Handle ChangeEvent case
      const { name, value } = e.target;
      
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };


  const handleTagsChange = (selectedOptions: any) => {
    setFormData({
      ...formData,
      tags: selectedOptions.map((option: any) => option.value),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();
    // Add the following line to set the form validation state
    setValidated(true);

    const form = e.currentTarget;
    
    if (form.checkValidity() === false || error?.end_date_err) {
      return;
    }
    
    const formDataForSubmission = new FormData();
    formDataForSubmission.append('title', formData?.title);
    formDataForSubmission.append('description', formData.description);
    formDataForSubmission.append('category', formData.category);
    formDataForSubmission.append('phase', formData.phase);
    formDataForSubmission.append('start_date', formData.start_date);
    formDataForSubmission.append('end_date', formData.end_date);
    formDataForSubmission.append('donar', formData.donar);
    // formDataForSubmission.append('tags', formData.tags.join(','));
    formData.tags.forEach((tag, index) => {
      formDataForSubmission.append(`tags[${index}]`, tag);
    });

    try {
      const response = await axios.post(`${Constants.BASE_URL}project/create`, formDataForSubmission, {
        headers: {
          'x-api-key': 'web_qwertyuiop', // Add custom headers here
          'x-access-token': userTkn,
          "Access-Control-Allow-Origin": "*"
        },
      });
      

      if (response.status === 200) {

        
        navigation("/")

      } else {
        console.error("Api Failed");
      }
    } catch (error: any) {
      console.error('Sign in failed:', error);
      const key = Object.keys(error.response?.data.errors)[0]
      setSubmitError(error.response?.data.errors[key]?.[0])

    }
  };

  useEffect(() => {
    const transformedData = tags?.map(item => ({
      value: item.id,
      label: item.name
    }));
    setTagsData(transformedData)
  }, [tags])
  const { config: { theme, navbarPosition }, setConfig, } = useAppContext();
  //set top navigation
  useEffect(() => {
    const value = "combo";
    const value2 = 'slim';
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });

  }, [])

  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <h2 className="mb-4"><Unicon icon={UilCube} size={28}/> Project Info</h2>

      <div className="navbar_actions mb-3 mx-0">
                <div className='d-flex gap-5 mobileSlide'>
                    <div className='d-flex align-items-center gap-4 '>
                      <Link to="" className='fs-9 text-decoration-none fw-bold text-700' >WIKI</Link>
                      <Link to={`projectinfo-section`} className='fs-9 text-decoration-none fw-bold text-700'>Sections</Link>
                      <Link to="" className='fs-9 text-decoration-none fw-bold text-700'>Feature List</Link>
                      <Link to="" className='fs-9 text-decoration-none fw-bold text-700'>Link</Link>
                      <Link to="" className='fs-9 text-decoration-none fw-bold text-700'>Files</Link>
                    </div>
                  
                   
                </div>
            </div>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Row>
          <Col xs={12} xl={12}>
            <Row className="g-3 mb-6">
              <Col sm={12} md={12}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label={`${Constants.project_title} *`}
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Project title"
                      name="title"
                      value={formData.title}
                      onChange={(e) => { handleInputChange(e, "title") }}

                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the project title.
                    </Form.Control.Feedback>
                  </FloatingLabel>

                </Form.Group>
              </Col>
         
          
              <Col sm={12} md={12}>
                <Form.Group controlId="imageUpload" className='input-group custom-file-button'>
                  <label className="input-group-text" htmlFor="inputGroupFile">Upload Icon</label>
                  <Form.Control type="file" size='lg' id='inputGroupFile'/>
                </Form.Group>
              </Col>

              <Col xs={12} className="">
                <FloatingLabel
                  controlId="floatingProjectOverview"
                  label="Project Discription"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: '100px' }}
                    name="description"
                    value={formData.description}
                    onChange={e => { handleInputChange(e, "description") }}
                  />
                </FloatingLabel>
              </Col>
            
              <Col xs={12} className="gy-6">
                {submitError && <div className="invalid-feedback" style={{ display: "block" }}>{submitError}</div>}
                <div className="d-flex justify-content-center gap-3">
                  <Button variant="phoenix-primary" className="px-5" onClick={() => { navigation('/'); }}>
                    Cancel
                  </Button>
                  <Button variant="outline-primary" type="submit" className="px-5 px-sm-10">
                  Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

    </div>
  );
};

export default ProjectInfoAdmin;
