import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import { Dispatch, SetStateAction } from "react";

interface AdminBreadeCrumbData {
    workspace?: string;
    pageName?: string;
    projectTitle?: string;
    setBreadCrumb: Dispatch<SetStateAction<PageBreadcrumbItem[]>>;
}
export const getAdminPageBreadCrumbs = ({ pageName, workspace, setBreadCrumb, projectTitle }: AdminBreadeCrumbData) => {
    switch (pageName) {
        case 'AdminEntityList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;
        case 'AdminEntityListView':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    },
                    {
                        label: 'Entity',
                        url: `/${workspace}/admin/entity/list`,
                    }
                ]);
            }
            break;

        case 'AdminUserList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;

        case 'AdminUserView':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    },
                    {
                        label: 'User',
                        url: `/${workspace}/admin/users/list`,
                    }
                ]);
            }
            break;
        case 'AdminProjectList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;
        case 'AdminProjectView':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    },
                    {
                        label: 'Project',
                        url: `/${workspace}/admin/project/list`,
                    }

                ]);
            }
            break;

        case 'AdminUpdateProject':
            if (workspace && projectTitle) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    },
                    {
                        label: `Project`,
                        url: `/${workspace}/admin/project/list`,
                    },
                    {
                        label: projectTitle,
                        url: `/${workspace}/admin/project/${projectTitle}/view`,
                    },
                ]);
            }
            break;

        case 'AdminCreateProject':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    },
                    {
                        label: `Project`,
                        url: `/${workspace}/admin/project/list`,
                    }
                ]);
            }
            break;

        case 'AdminMetaList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;

        case 'AdminProjectCategoryList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;

        case 'AdminDesignationList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;

        case 'AdminMeetingLocationCategoryList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;

        case 'AdminMeetingLocationList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;
        case 'AdminMeetingCategoryList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;

        case 'AdminPhasesList':
            if (workspace) {
                setBreadCrumb([
                    {
                        label: '',
                        url: `/${workspace}`,
                    },
                    {
                        label: workspace,
                        active: true,
                    },
                    {
                        label: 'Admin',
                        url: `/${workspace}/admin/home`,
                    }
                ]);
            }
            break;

    }
}
