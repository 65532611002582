import React, { ReactNode, createContext, useContext, useState } from "react";

type VersionContextType = {
    version: string;
};

const VersionContext = createContext<VersionContextType | undefined>(undefined);

export function useVersion() {
    const context = useContext(VersionContext);
    if (!context) {
        throw new Error('useVersion must be used within a VersionProvider');
    }
    return context;
}

type VersionProviderProps = {
    children: ReactNode;
};

export function VersionProvider({ children }: VersionProviderProps) {
    const [version, ] = useState("Beta");

    // Create the context value with the state value
    const contextValue: VersionContextType = {
        version: version
    };

    return (
        <VersionContext.Provider value={contextValue}>
            {children}
        </VersionContext.Provider>
    );
}
