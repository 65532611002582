import axios, { AxiosError } from 'axios';
import Button from 'components/base/Button';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import Constants from 'common/Constants';
import apiCall from 'services/api';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from 'providers/AuthContext';

const InvitationAuthenticationPage = () => {
  const { userTkn, setWorkspace, setWorkSpaceTkn, setWorkspaceList, setIsUserAdmin } = useAuth()
  const navigation = useNavigate() as NavigateFunction;
  const location = useLocation();
  const [showInvitationBtn, setShowInvitationBtn] = useState(false);
  const [showGoBack, setShowGoBack] = useState(false)
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get('token');
  const [error, setError] = useState({
    token: "",
    password: ""
  })


  useEffect(() => {
    // Verify Token
    const verifyToken = async (resetToken: any) => { // Remove :String

      try {
        setLoading(true)
        const response = await axios.get(`${Constants.BASE_URL}validate-invitation-token/${resetToken}`, {
          headers: {
            'x-api-key': 'web_qwertyuiop', // Add custom headers here
            "Access-Control-Allow-Origin": "*",
            'x-access-token': userTkn,
          },
        });
        

        if (response.status === 200) {

          // Dynamically set errors based on the response
          if (response?.data?.errors) {
            const errors = response?.data?.errors;

            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setError((prevData) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            console.warn("first api response", response.data);
            if (response?.data?.status === true) {
              console.log(response)
              setShowInvitationBtn(true)
            }
          }
        } else {
          console.error("api failed");
        }
      } catch (error: any) {
        console.error('Sign in failed:', error.response);
        if ((error as AxiosError).response?.status === 400) {
          console.log("inside 403")
          toast.error(error?.response?.data?.message, {
            position: "top-center"
          });
        } else {
          toast.error(error?.message, {
            position: "top-center"
          });
        }
        setShowGoBack(true)
      }finally{
        setLoading(false)
      }
    };
    verifyToken(resetToken);
  }, [resetToken]);



  const acceptingInvitation = async () => {

    try {
      setLoading(true)
      const response = await apiCall({
        url: `accept-invitation/${resetToken}`,
        method: 'POST',
        headers: {
          'x-api-key': 'web_qwertyuiop', // Add custom headers here
          "Access-Control-Allow-Origin": "*",
          'x-access-token': userTkn,
        },
      });
      console.log("accept invitation", response)
      if (response?.status === 200) {
        const [profileResponse, workspaceResponse] = await Promise.all([
          axios.post(
            `${Constants.BASE_URL}profile`,
            null,
            {
              headers: {
                "x-api-key": "web_qwertyuiop",
                "x-access-token": userTkn,
                "Access-Control-Allow-Origin": "*",
              },
            }
          ),
          axios.post(
            `${Constants.BASE_URL}workspaces`,
            null,
            {
              headers: {
                "x-api-key": "web_qwertyuiop",
                "x-access-token": userTkn,
                "Access-Control-Allow-Origin": "*",
              },
            }
          ),
        ]);

        if (profileResponse.status === 200 && workspaceResponse.status === 200) {
          console.log(workspaceResponse)
          const newWorkspaceUUid = response?.data?.uuid;
      

          const defaultWorkspace =(workspaceResponse?.data?.Workspaces?.data).find((workspace: any) => workspace.uuid !== newWorkspaceUUid);
          console.log(defaultWorkspace)
          setIsUserAdmin(defaultWorkspace?.is_super_admin);
          setWorkspace(defaultWorkspace);
          setWorkSpaceTkn(defaultWorkspace.uuid);
          // const updatedWorkSpaceList = [defaultWorkspace, ...(workspaceResponse?.data?.Workspaces?.data).filter((workspace: any) => workspace.alias !== defaultWorkspace.alias)];
          setWorkspaceList(workspaceResponse?.data?.Workspaces?.data)
          console.log(`/${defaultWorkspace.alias}/project/${response?.data?.alias}/overview`)
          if(defaultWorkspace?.alias && response?.data?.alias){
            navigation(`/${defaultWorkspace.alias}/project/${response?.data?.alias}/overview`)
          }else if(defaultWorkspace.alias){
            navigation(`/${defaultWorkspace.alias}/project/list`)
          }else{
            navigation(`/${defaultWorkspace.alias}`)
          }        
        }
      }


    }
    catch (error: any) {
      console.error('Sign in failed:', error);
      if ((error as AxiosError).response?.status === 403) {
        console.log("inside 403")
        toast.error(error?.response?.data?.message, {
          position: "top-center"
        });
     
      } else {
        toast.error(error?.message, {
          position: "top-center"
        });
      }
      setShowInvitationBtn(false)
      setShowGoBack(true)
    }finally{
      setLoading(false)
    }
  }
  return (
    <div className="text-center mb-6 mt-2">
      <h4>Auth Invitation</h4>
      {showInvitationBtn && 
      <Button
        variant="outline-primary"
        className="w-100 "
        type="submit"
        disabled={loading} // Disable the button when loading is true
        onClick={() => { acceptingInvitation() }}
      >
        {loading ? (
          <>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Accepting Invitation...
          </>
        ) : (
          "Accept Invitation"
        )}
      </Button>
      }  
      { showGoBack &&  <Link  to={`/sign-in`} className="fs-9 fw-semi-bold">
          {"Go Back?"}
        </Link>}
      <ToastContainer />
    </div>
  );
};

export default InvitationAuthenticationPage;
