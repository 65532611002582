import Avatar from 'components/base/Avatar';
import React, { AnchorHTMLAttributes, HTMLAttributes, useEffect, useState } from 'react';
import { Button, Card, Dropdown, Form, Nav, } from 'react-bootstrap';
import avatar from 'assets/img/icons/star.svg';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useAuth } from 'providers/AuthContext';
import Avatar3 from 'components/base/Avatar3';
import { useMaster } from 'providers/MasterContext';

interface UserData {
  first_name: string;
  last_name: string;
  short_name: string;
  email: string;
  // Add other properties if needed
}

interface CustomDropdownToggleProps extends HTMLAttributes<HTMLDivElement> {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { signOut, user, setWorkspace, setWorkSpaceTkn, setIsUserAdmin, workSpace, workSpaceList, isUserAdmin } = useAuth();
  const { workspace } = useParams();
  const { refetchData } = useMaster();
  const [userData, setUserdata] = useState<UserData | null>(user || null);
  const navigate = useNavigate();

  useEffect(() => {
    // Update userData whenever user changes
    if (user !== null) {
      setUserdata(user)
    }
  }, [user]);


  const handleSignOut = () => {
    signOut()
  }

  const handleNavItemClick = (item: any, event: any) => {
    event.preventDefault(); // Prevent default behavior of anchor tag
    setIsUserAdmin(item?.is_super_admin)
    setWorkSpaceTkn(item?.uuid)
    setWorkspace(item)


    localStorage.setItem(
      "isUserAdmin",
      JSON.stringify(item?.is_super_admin)
    );
    localStorage.setItem(
      "workspaceToken",
      JSON.stringify(item?.uuid)
    );
    localStorage.setItem(
      "selectedWorkSpace",
      JSON.stringify(item)
    );
    refetchData()
    navigate(`/${item.alias}/project/list`)

  }
  const CustomDropdownToggle = React.forwardRef<HTMLDivElement, CustomDropdownToggleProps>(
    ({ children, onClick, ...rest }, ref) => (
      <div
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}

        ref={ref}
        {...rest}
      >
        {children}
      </div>
    )
  );

  const handleSeeAll = () =>{
    
  }

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-1 pt-4 pb-3">
            <Avatar3 size="xl" variant="name" className="me-3" border={"2"}>{(userData as UserData)?.short_name}</Avatar3>

            <h6 onClick={()=>{navigate(`/${workSpace?.alias}/user/profile`)}} className="text-black user-profile-name">{(userData as UserData)?.first_name} {(userData as UserData)?.last_name}</h6>
            
            <p className='text-600 ms-1'>{(userData as UserData)?.email}</p>
          </div>
          <Nav className="nav flex-column mb-2 pb-1">
            {workSpaceList?.slice(0, 2).map(item => (
              <Dropdown.Toggle as={CustomDropdownToggle} key={item.title} className={`dropdown-caret-none p-1  ${workSpace?.alias === item?.alias ? "active-workspace" : ""}`}>
                <div
                  className="px-3"
                  onClick={(e) => handleNavItemClick(item, e)}
                >

                  <span className="text-1000">{item.title}</span>
                </div>
              </Dropdown.Toggle>
            ))}
            {

              (workSpaceList && workSpaceList?.length > 2) &&
              <Dropdown.Toggle as={CustomDropdownToggle} className={`dropdown-caret-none p-1`}>
                <div onClick={()=>{navigate(`/admin/all-workspace/list`)}} className="text-decoration-none px-3 py-2">
                  See All
                </div>
              </Dropdown.Toggle>
            }
          </Nav>
        </Card.Body>
        <Card.Footer className="p-0 pt-3">
          <div className="px-3">
            <Button
              // to="#!"
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
              onClick={() => { handleSignOut() }}
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-600">

          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
