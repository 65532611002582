// PrivateRoute.tsx
import { useAuth } from 'providers/AuthContext';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type PrivateRouteProps = {
  path?: string;
  element?: React.ReactNode; 
};

export function PrivateRouteAdmin({ path, element }: PrivateRouteProps) {
  const { isAuthenticated, isUserAdmin } = useAuth();


    if (!isAuthenticated) {
      return <Navigate to="/sign-in" />;
    } else if (!isUserAdmin) {
      return <Navigate to="*" />;
    } else {
      return <>{element}</>;
    }


}

