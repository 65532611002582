import ForgotPasswordForm from 'components/modules/auth/ForgotPasswordForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bg from 'assets/img/bg/30.png';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';

const ForgotPassword = () => {
  useSettingsMountEffect({
    showSettingPanelButton: false
  });
  return (
    <AuthSplitLayout bg={bg}>
      <ForgotPasswordForm layout="split" />
    </AuthSplitLayout>
  );
};

export default ForgotPassword;
