import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import regexPatterns from "common/regexPatterns";
import { dbDateWithTimeStamp } from "common/timeStampToDate";
import Button from "components/base/Button";
import DatePicker2 from "components/base/DatePicker2";
import ReactSelect from "components/base/ReactSelect";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";

interface AddActionPointModalProps {
  show: boolean;
  handleClose: () => void;
  setAddStatus: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
  toast: any;
  presentUser: any;
  projectData: any;
  navigation: any;
}

interface Option {
  value: string;
  label: string;
}

interface FormData {
  id: string;
  project_name: string;
  title: string;
  description: string;
  due_date: string;
  assign_to: string;
  auditor_id: string;
  viewers: string[]; // Explicitly define the type as an array of numbers
}

const AddActionPointModal = ({ show, handleClose, setAddStatus, toast, navigation, presentUser, projectData }: AddActionPointModalProps) => {

  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [viewerOptions, setViewerOptions] = useState([]);
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [error, setError] = useState({
    project_name: "",
    title: "",
    description: "",
    due_date: "",
    assign_to: "",
    auditor_id: "",
    viewers: "",
  });
  const [formData, setFormData] = useState<FormData>({
    id: "",
    project_name: projectData.title,
    title: "",
    description: "",
    due_date: "",
    assign_to: "",
    auditor_id: "",
    viewers: []
  });

  useEffect(() => {

    setOptions(prevOptions => (
      presentUser.map((item: any) => ({ // Assuming 'any' type for 'item' for now
        value: item.id,
        label: `${item.first_name} ${item.last_name}`
      }))
    ));
  }, [presentUser]);


  useEffect(() => {
    // Filter out the presentUser list to exclude assign_to and auditor_id
    const filteredOptions = presentUser?.filter((item: any) => {
      return (
        Number(item.id) !== Number(formData?.assign_to) && Number(item?.id) !== Number(formData?.auditor_id)
      );
    });


    setViewerOptions(prevOptions => (
      filteredOptions.map((item: any) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`
      }))
    ));

  }, [presentUser, formData?.assign_to, formData?.auditor_id]);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: string; } }) => {
    const { name, value } = e.target;

    let errorMessage = "";

    // Validation for first name
    if (name === "title") {
      if (!value.trim()) {
        errorMessage = "Title is required.";
      } else if (!regexPatterns.titleRegex255.test(value)) {
        errorMessage = "Title should contain at 3-255 characters [a-zA-Z0-9] with space.";
      }
    }


    if (name === "due_date") {
      let newValue = dbDateWithTimeStamp(value)
      setFormData({
        ...formData,
        [name]: newValue,
      });

    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

    }


    setError((prevData) => ({
      ...prevData,
      [name]: errorMessage,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();
    // Add the following line to set the form validation state
    setValidated(true);

    // Check if there are any errors before making the API call
    if (
      error.title ||
      error.assign_to ||
      error.due_date ||
      error.auditor_id ||
      error.viewers ||
      error.description
    ) {
      return;
    }
    // Check if there are any errors before making the API call
    if (
      !formData.title.trim() ||
      !formData.assign_to ||
      !formData.due_date.trim() ||
      !formData.auditor_id.trim() ||
      !formData.description.trim()

    ) {
      setError({
        ...error,
        title: !formData.title.trim() ? "Title is required." : "",
        assign_to: !formData.assign_to ? "This is required." : "",
        due_date: !formData.due_date.trim() ? "Date is required." : "",
        auditor_id: !formData.auditor_id ? "Auditor is required." : "",
        description: !formData.description.trim() ? "Description is required." : "",
      });
      return;
    }
    // Perform any necessary logic or API calls here

    const formDataSubmit = new FormData();
    formDataSubmit.append("title", formData?.title);
    formDataSubmit.append("assign_to", formData?.assign_to);
    formDataSubmit.append("due_date", formData?.due_date);
    formDataSubmit.append("auditor_id", formData?.auditor_id);
    formDataSubmit.append("description", formData?.description);
    // Append each viewer ID to the formDataSubmit object
    formData.viewers.forEach(viewerId => {
      formDataSubmit.append("viewers[]", viewerId);
    });

    try {
      setLoading(true)
      const response = await apiCall({
        url: `project/${projectData.alias}/actionpoints/create`,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });


      if (response.status === 200) {

        if (response?.data?.errors) {
          const errors = response?.data.errors;
          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setError((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          // Call the refetchData function from the context to fetch the data again
          setAddStatus(true);
          handleClose();
        }
      } else {
        console.error("Api Failed");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  const handleViewerChange = (selectedOptions: any) => {
    setFormData({
      ...formData,
      viewers: selectedOptions.map((option: any) => option.value),
    });
  };
  return (
    <Modal show={show} onHide={handleClose} className="p-0" size="lg">
      <Modal.Header className="border-200 p-4">
        <h5 className="modal-title text-1000 fs-7 lh-sm">Add New Action Item</h5>
        <Button className="p-1" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} className="fs-9" />
        </Button>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body className="pt-4 pb-2 px-4" >
          <Row >
            <Col md={12} className="mb-3">
              <FloatingLabel
                controlId="floatingInputGrid"
                label='Project Name'
              >
                <Form.Control
                  disabled
                  required
                  type="text"
                  placeholder="Re-Password"
                  name="project_name"
                  value={formData.project_name}
                  onChange={handleInputChange}
                  isInvalid={!!error.project_name}
                />
                <Form.Control.Feedback type="invalid">
                  {error?.project_name}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  project is already selected can't change here.
                </Form.Text>
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className=" text-start">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label={`Title *`}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    isInvalid={!!error.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error?.title}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="lead-woner" label="Assign To *">
                <Form.Select
                  required
                  value={formData.assign_to}
                  name="assign_to"
                  className="w-100"
                  onChange={handleInputChange}
                  isInvalid={!!error.assign_to}
                >
                  <option value="">Select</option>
                  {
                    options?.map((item: any, index: number) => (
                      <option value={item.value} key={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {error?.assign_to}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="text-start">
                <DatePicker2
                  value={formData.due_date ? new Date(formData.due_date).toLocaleDateString('en-GB') : ''}
                  onChange={(date: Date[]) => {
                    handleInputChange({
                      target: {
                        name: 'due_date',
                        value: date[0].toString()
                      }
                    });
                  }}
                  options={{
                    enableTime: true,
                    dateFormat: "d/m/Y h:i K",
                    minDate: new Date()
                  }}
                  isError={error?.due_date}
                  id="due_date"
                  name="due_date"
                  render={(_, ref) => {
                    const isInvalid = !!error.due_date;
                    return (
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          ref={ref}
                          placeholder="d/m/Y  h:i"
                          readOnly
                          isInvalid={isInvalid}
                        />
                        <label htmlFor="startDate" className="ps-6">
                          {`Due Date *`}
                        </label>
                        <Form.Control.Feedback type="invalid">
                          {error?.due_date}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    );
                  }}
                />

              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel controlId="lead-woner" label="Auditor *">
                <Form.Select
                  required
                  value={formData.auditor_id}
                  name="auditor_id"
                  className="w-100"
                  onChange={handleInputChange}
                  isInvalid={!!error.auditor_id}
                >
                  <option value="">Select</option>
                  {
                    options?.map((item: any, index: number) => (
                      <option value={item.value} key={item.value}>{item.label}</option>
                    ))
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {error?.auditor_id}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md={12} className="mb-3">

              <ReactSelect
                name="viewers"
                options={viewerOptions}
                onChange={handleViewerChange}
                value={viewerOptions?.filter((option: any) => formData?.viewers?.includes(option?.value))}
                isMulti
                placeholder="Select Viewers"
                required
                classNames={{
                  control: () => 'py-3',
                  valueContainer: () => 'lh-1'
                }}
              />

            </Col>




          </Row>
          <Row className="mb-3 d-flex gap-1">
            <Col>
              <Form.Group className=" text-start">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label={`Description *`}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    isInvalid={!!error.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error?.description}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
          <Button
            variant="phoenix-primary"
            size="sm"
            className="px-9 fs-9  my-0 btn btn-outline-primary bg-transparent"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddActionPointModal;
