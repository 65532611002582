import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Route } from 'sitemap';
import { capitalize } from 'helpers/utils';
import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useNavbarVerticalCollapse } from './NavbarVerticalCollapseProvider';
import Badge from 'components/base/Badge';
import { useAppContext } from 'providers/AppProvider';


interface NavbarVerticalMenuCustomProps {
  routes: Route[];
  level: number;
  alias: string;
  workspace: string;
  state: any
}

interface NavItemProps {
  route: Route;
  level: number;
  state: any,
  alias: string;
  workspace:string;
}

const NavItem = ({ route, level, state, alias, workspace }: NavItemProps) => {
  const { config: { isNavbarVerticalCollapsed } } = useAppContext();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();

  return (
    <>
      
        <Nav.Item as="li">
          <NavLink
            to={route.path ? `${workspace}/project/${alias}/${route.path}`: '#!'}
            state={{ user: state?.user }}
            className={({ isActive }) =>
              classNames('nav-link', {
                'label-1': level === 1,
                active: isActive && route.path !== '#!'
              })
            }
            onClick={() => level === 1 && setOpenItems(openItems.map(() => ''))}
          >
            <div
              className={classNames('d-flex align-items-center', {
                'text-300': !route.active
              })}
            >
              {route.icon ? (
                <>
                  <span
                    className={classNames('nav-link-icon', {
                      // new: route.new || route.hasNew
                    })}
                  >
                    {route.iconSet === 'font-awesome' ? (
                      <FontAwesomeIcon
                        icon={route.icon as IconProp}
                        className="fs-8 mx-1"
                      />
                    ) : (
                      <FeatherIcon icon={route.icon} size={16} />
                    )}
                  </span>
                  <span className="nav-link-text-wrapper">
                    <span className="nav-link-text">{capitalize(route.name)}</span>
                    {route.new && !isNavbarVerticalCollapsed && (
                      <Badge variant="phoenix" bg="info" className="ms-2">
                        New
                      </Badge>
                    )}
                  </span>
                </>
              ) : (
                <>
                  <span className="nav-link-text ">{capitalize(route.name)}</span>
                  {route.new && (
                    <Badge variant="phoenix" bg="info" className="ms-2">
                      New
                    </Badge>
                  )}
                </>
              )}
            </div>
          </NavLink>
        </Nav.Item>
   
    </>

  );
};



const NavbarVerticalMenuCustom = ({ routes, level, alias, workspace  }: NavbarVerticalMenuCustomProps) => {
  let { state } = useLocation();
  const navigate = useNavigate()
  // Check if state is null and navigate to home page
  useEffect(() => {
    if (state === null) {
      navigate(`/${workspace}`);
    }
  }, [])

  return (
    <>

      {routes.map(route => (
        <div key={route.name}>
          {level === 1 ? (
            <div className="nav-item-wrapper">
              {route.pages ? (
                <>

                  <NavItem route={route} level={level} state={state} alias={alias} workspace={workspace}/>
                </>

              ) : (
                <NavItem route={route} level={level} state={state}  alias={alias} workspace={workspace}/>
              )}
            </div>
          ) : (
            <>
              {route.pages ? (
                <NavItem route={route} level={level} state={state}  alias={alias} workspace={workspace}/>
              ) : (
                <NavItem route={route} level={level} state={state}  alias={alias} workspace={workspace}/>
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default NavbarVerticalMenuCustom;
