import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilCube,
  UilHome,
  UilReceipt
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  noDropDown?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'dashboard',
    active: true,
    icon: UilHome,
    labelDisabled: true,
    megaMenu: false,
    noDropDown: true,
    pages: [ ]
  },
  {
    label: 'project',
    horizontalNavLabel: 'project',
    active: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: 'Overview',
        icon: 'map',
        active: true,
        hasNew: true,
        flat: true,
        path: 'overview',
        pages: [
         
        ]
      },
      {
        name: 'Project Info',
        icon: 'box',
        active: true,
        hasNew: true,
        flat: true,
        path: 'project-info',
        pages: [
         
        ]
      },
      {
        name: 'Diary',
        icon: 'mail',
        active: true,
        hasNew: true,
        flat: true,
        path: '#!',
        pages: [
         
        ]
      },
      {
        name: 'Discussion',
        icon: 'layers',
        active: true,
        hasNew: true,
        flat: true,
        path: '#!',
        pages: [
         
        ]
      },
      {
        name: 'Meetings',
        icon: 'message-square',
        active: true,
        hasNew: true,
        flat: true,
        path: '#!',
        pages: [
         
        ]
      },
      {
        name: 'Note',
        icon: 'file-text',
        active: true,
        hasNew: true,
        flat: true,
        path: 'notes',
        pages: [
         
        ]
      },
      {
        name: 'Action Items',
        icon: 'pie-chart',
        active: true,
        hasNew: true,
        flat: true,
        path: 'action-point',
        pages: [
         
        ]
      },
      {
        name: 'Story',
        icon: 'briefcase',
        active: true,
        hasNew: true,
        flat: true,
        path: 'project-story',
        pages: [
         
        ]
      },
      {
        name: 'Deliverables',
        icon: 'shopping-cart',
        active: true,
        hasNew: true,
        flat: true,
        path: '#!',
        pages: [
         
        ]
      },
      {
        name: 'Milestones',
        icon: 'command',
        active: true,
        hasNew: true,
        flat: true,
        path: '#!',
        pages: [
         
        ]
      },
      {
        name: 'Calendars',
        icon: 'calendar',
        active: true,
        hasNew: true,
        flat: true,
        path: '#!',
        pages: [
         
        ]
      },
      {
        name: 'Settings',
        icon: 'settings',
        active: true,
        hasNew: true,
        flat: true,
        path: 'setting',
        pages: [
         
        ]
      }


    ]
  }
];
