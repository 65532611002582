interface ClientsItem {
    id: number;
    name: string;
    short_name: string;
    alias: string;
    status: number;
  }
 interface PhasesItem {
    id: number;
    title: string;
}

interface ProjectStatusItem {
  id: number;
  title: string;
}

interface ProjectCategoryItem{
  id: number;
  title: string;
}
interface UserDesignationItem{
  id: number;
  title: string;
}

export function getPhaseNameById(id: number, phases: PhasesItem[] | null): string {
    if (phases) {
      const phase = phases?.find((phase) => phase.id === id);

      // Check if a project with the given id was found
      if (phase) {
        return phase.title;
      } else {
        return ""; // or handle the case when the project is not found
      }
    } else {
      return ""; // Handle the case when clients is null
    }
  }

  export function getEntityNameById(id: number, clients: ClientsItem[] | null): string {
    if (clients) {
      const client = clients.find((client) => client.id === id);
  
      // Check if a project with the given id was found
      if (client) {
        return client.name;
      } else {
        return ""; // or handle the case when the project is not found
      }
    } else {
      return ""; // Handle the case when clients is null
    }
  }

  export function getStatusNameById(id: number, status: ProjectStatusItem[] | null): string {
    if (status) {
      const st = status.find((st) => st.id === id);
  
      // Check if a project with the given id was found
      if (st) {
        return st.title;
      } else {
        return ""; // or handle the case when the project is not found
      }
    } else {
      return ""; // Handle the case when clients is null
    }
  }


  export function geCategoryNameById(id: number, category: ProjectCategoryItem[] | null): string {
    if (category) {
      const data = category.find((category) => category.id === id);
  
      // Check if a project with the given id was found
      if (data) {
        return data.title;
      } else {
        return ""; // or handle the case when the project is not found
      }
    } else {
      return ""; // Handle the case when clients is null
    }
  }



  export function geDesignationNameById(id: number, designation: UserDesignationItem[] | null): string {
    if (designation) {
      const data = designation.find((designation) => designation.id === id);
  
      // Check if a project with the given id was found
      if (data) {
        return data.title;
      } else {
        return ""; // or handle the case when the project is not found
      }
    } else {
      return ""; // Handle the case when clients is null
    }
  }