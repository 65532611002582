import { lazy, Suspense, useState, useEffect, ChangeEvent, useRef } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { Button, Col, FloatingLabel, Form, Placeholder, Row, Table } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import apiCall from "../../services/api"; // Adjust the path based on your project structure
const AddMeetingLocationModal = lazy(() => import("./AddMeetingLocationModal"))
const UpdateMeetingLocationModal = lazy(() => import("./UpdateMeetingLocationModal"));
import Unicon from 'components/base/Unicon';
import { UilPen, UilToggleOff, UilToggleOn, UilTrash } from '@iconscout/react-unicons';
import calculateSerialNumber from "common/calculateSerialNumber";
import SearchInput from "components/sp-common/SearchInput";
import CustomPagination from "components/sp-common/CustomPagination";
import { toast, ToastContainer } from 'react-toastify';
import ConfirmationModal from "common/ConfirmModal";
import { getAdminPageBreadCrumbs } from "services/getAdminPageBreadCrumbs";
import { useMaster } from "providers/MasterContext";
import { handleCutomError } from "services/handleCutomError";



// Assuming you have a type for your data objects
interface MeetingLocationCategory {
  id: number;
  title: string;
  status: number;
}

interface MeetingLocation {
  id: number;
  title: string;
  meeting_location_category_id: number;
  status: number;
  serialNumber: string
  meetinglocationcategory: MeetingLocationCategory;
}
// Define the type for the page filters state
type PageFiltersState = {
  status: string;
  titleSearch: string;
  meeting_location_category_id: string;
};

type MeetingLoctionDelete = {
  id: number;
  title: string | null;
};


const MeetingLocationList = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const { refetchData } = useMaster()
  const [pageName, setPageName] = useState("AdminMeetingLocationList")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [data, setData] = useState<MeetingLocation[] | null>(null);
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 0,
    "pageSize": 0,
    "total_page": 0,
  });
  const [pageIndex, setPageIndex] = useState<number | null>(1);
  const [openMeetingLocationModal, setOpenMeetingLocationModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [addStatus, setAddStatus] = useState<boolean>(false); const tableRef = useRef<HTMLTableElement | null>(null);
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);
  const [meetingLocationCategory, setMeetingLocationCategory] = useState([{
    id: "",
    title: ""
  }])
  const [meetingCategoryUpdateData, setMeetingCategoryUpdateData] = useState({
    id: 0,
    title: "",
    status: 0,
    meeting_location_category_id: 0
  });
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    status: "1",
    titleSearch: "",
    meeting_location_category_id: ""
  })

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmedItemAlias, setConfirmedItemAlias] = useState<number | null>(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [itemId, setItemId] = useState<MeetingLoctionDelete>({
    id: 0,
    title: ""
  });

  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();


  //check workspace 
  useEffect(() => {
    if (!workSpaceTkn) {
      navigation("/admin/all-workspace/list")
    }
  }, [])

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, workspace]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true
      try {
        const response = await apiCall({
          url: `admin/master/master-meeting-location/list?page=${pageIndex}&meeting_location_category_id=${pageFilters.meeting_location_category_id}&status=${pageFilters.status}`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response.status === 200) {
          const updatedList = response?.data?.MasterMeetingLocation?.data.map((designation: any, index: any) => ({
            ...designation,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.MasterMeetingLocation?.summary?.pageSize) // Assuming 10 items per page
          }));
          setAddStatus(false)
          setData(updatedList);
          let summary = response.data.MasterMeetingLocation.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [userTkn, pageIndex, addStatus, pageFilters.meeting_location_category_id, pageFilters.status]);

  useEffect(() => {
    const fetchData = async () => {

      setLoading(true); // Set loading to true
      try {
        const response = await apiCall({
          url: `admin/master/master-meeting-location-category/list?pagination=0`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response.status === 200) {
          const newData = response?.data?.MasterMeetingLocationCategory?.data?.map((item: any) => ({
            value: item.id,
            label: `${item.title}`,
          }));
          setMeetingLocationCategory(newData);

        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [])

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: data || [],
    columns: projectsTablecolumns,
    pageSize: 2,
    pagination: true,
    sortable: true,
  });

  const searchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: e.target.value
    }))
  };

  const handlePageFilters = (value: string) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: "",
    }));
  }

  const handleSearchInputReq = (value: string) => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let searchUrl = ""
        if (value === "search") {
          searchUrl = `admin/master/master-meeting-location/list?page=${pageIndex}&meeting_location_category_id=${pageFilters.meeting_location_category_id}&status=${pageFilters.status}&title=${pageFilters.titleSearch}`
        } else {
          searchUrl = `admin/master/master-meeting-location/list?page=${pageIndex}&meeting_location_category_id=${pageFilters.meeting_location_category_id}&status=${pageFilters.status}&title=`
        }
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });


        if (response?.status === 200) {
          const updatedList = response?.data?.MasterMeetingLocation?.data.map((designation: any, index: any) => ({
            ...designation,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.MasterMeetingLocation?.summary?.pageSize) // Assuming 10 items per page
          }));
          setAddStatus(false)

          setData(updatedList);
          let summary = response.data.MasterMeetingLocation.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  };

  const handleOnBlur = async (value: any) => {
    if (value !== "") {
      let searchUrl = ""

      searchUrl = `admin/master/master-meeting-location/list?page=${pageIndex}&meeting_location_category_id=${pageFilters.meeting_location_category_id}&status=${pageFilters.status}&title=${pageFilters?.titleSearch}`;

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          const updatedList = response?.data?.MasterMeetingLocation?.data.map((designation: any, index: any) => ({
            ...designation,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.MasterMeetingLocation?.summary?.pageSize) // Assuming 10 items per page
          }));
          setAddStatus(false)
          setData(updatedList);
          let summary = response.data.MasterMeetingLocation.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const ChangeStatus = async (id: number) => {
    try {
      setLoading(true);
      const response = await apiCall({
        url: `admin/master/master-meeting-location/${id}/changestatus`,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      if (response.status === 200) {
        // Ensure that newData is always a valid array
        const newData: MeetingLocation[] =
          data?.map((item) => {
            if (item.id === response.data.MasterMeetingLocation.id) {
              item.status = response.data.MasterMeetingLocation.status;
            }
            return item;
          }) || [];

        setData(newData);
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };



  useEffect(() => {
    // Now 'tableRef.current' contains the reference to the table element
    const table = tableRef.current;

    if (table) {
      const firstRow = table?.rows[1];
      const lastRowIndex = table?.rows.length - 1;
      const lastRow = table?.rows[lastRowIndex];
      if (firstRow) {
        const firstCell = firstRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueFirst(value)
        }
      }

      if (lastRow) {
        const firstCell = lastRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueLast(value)
        }
      }
    }
  }, [data]);

  const getStatusIcon = (status: number, id: number) => {
    if (status === 1) return <Unicon icon={UilToggleOn} size={23} color="" fill="#8FC644" onClick={() => { setConfirmedItemAlias(id); setShowConfirmation(true); }} />
    else if (status === 0) return <Unicon icon={UilToggleOff} size={23} color="" fill="#6E7891" onClick={() => { setConfirmedItemAlias(id); setShowConfirmation(true); }} />
    else return <div></div>
  }

  const confirmStatusChange = async () => {
    // Call ChangeStatus function
    if (confirmedItemAlias) {
      await ChangeStatus(confirmedItemAlias);
      setShowConfirmation(false);
    }
  };


  const confirmDeleteMeetingLocation = async () => {
    // Call ChangeStatus function
    if (itemId.id) {
      await DeleteMetingLocation(itemId.id);
      setDeleteConfirmation(false);
    }
  };
  const DeleteMetingLocation = async (id: number) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('id', String(id));
      const response = await apiCall({
        url: `admin/master/master-meeting-location/${id}/delete`,
        method: 'POST',
        data: formData,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      if (response.status === 200) {
        refetchData()
        setAddStatus(true)
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4  justify-content-between align-items-center">
          <h2 className="mb-0">
            <span className="">Meeting Location</span>{" "}
          </h2>
          <Button
            className="btn btn-phoenix-primary px-5"
            onClick={() => setOpenMeetingLocationModal(true)}
          >

            <FontAwesomeIcon icon={faPlus} className="me-2" />
            New Location
          </Button>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <Col xs={12} sm="auto">
            <div className="d-flex flex-wrap gap-3 ">
              <SearchInput
                pageFilters={pageFilters}
                handleSearchInputChange={searchInputOnChange}
                handleSearchInputBlur={handleSearchInputReq}
                handlePageFilters={handlePageFilters}
                handleOnBlur={handleOnBlur}
                title="Title"
              />
              <div className="scrollbar overflow-hidden-y d-flex gap-2">
                <FloatingLabel controlId="lead-woner" label="Location Category">
                  <Form.Select
                    value={pageFilters.meeting_location_category_id}
                    onChange={(e) => {
                      setPageFilters((prevData) => ({
                        ...prevData,
                        meeting_location_category_id: e.target.value
                      }))
                    }}
                  >
                    <option value="">Select</option>
                    {
                      meetingLocationCategory?.map((item: any, index: number) => (
                        <option value={item.value} key={index}>{item.label}</option>
                      ))
                    }
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className="scrollbar overflow-hidden-y d-flex gap-2">
                <FloatingLabel controlId="lead-woner" label="Status">
                  <Form.Select
                    value={pageFilters.status}
                    onChange={(e) => {
                      setPageFilters((prevData) => ({
                        ...prevData,
                        status: e.target.value
                      }))
                    }}
                  >
                    <option value="1">Enable</option>
                    <option value="0">Disable</option>
                  </Form.Select>
                </FloatingLabel>
              </div>
            </div>
          </Col>
        </div>
        <div className="d-md-block">
          <PhoenixDocCard className="p-sm-4 p-2 mt-3">
            <Table
              className="phoenix-table fs-9 project-table border rounded"
              size="sm"
              striped
              hover
              responsive
              ref={tableRef}
            >
              <thead>
                <tr id={"a1"}>
                  <th style={{ width: "2%", maxWidth: "20px", padding: "16px 0px" }}>#</th>
                  <th className="px-1" style={{ width: "42%", minWidth: "200px" }} >Location</th>
                  <th className="px-1" style={{ width: "25%", minWidth: "200px" }}> Meeting Location Category</th>
                  <th className="px-1" style={{ width: "15%", minWidth: "200px" }}> {Constants.status}</th>
                  <th className="px-1" style={{ width: "15%", minWidth: "200px" }}>{Constants.action}</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item: MeetingLocation, index: number) => {
                    return (
                      <tr key={item?.id || index}>
                        <td>
                          {loading ? (
                            <Placeholder xs={7} />
                          ) : (item?.serialNumber)}
                        </td>
                        <td>
                          {loading ? (
                            <Placeholder xs={7} />
                          ) : (
                            <Link
                              to="#"
                              className="text-decoration-none fw-semi-bold fs-8 disabled_link text-black"
                            >
                              {item?.title}
                            </Link>
                          )}
                        </td>
                        <td>
                          {loading ? (
                            <Placeholder xs={7} />
                          ) : (item?.meetinglocationcategory?.title)}
                        </td>
                        <td className="toggle_border">
                          {loading ? (
                            <Placeholder xs={7} />
                          ) : (

                            getStatusIcon(item.status, item.id)
                          )}
                        </td>
                        <td>
                          {loading ? (
                            <Placeholder xs={7} />
                          ) : (
                            <div className="d-flex gap-1">
                              <Button
                                variant="phoenix-primary"
                                className="btn-icon me-2 rounded-1 bg-transparent"
                                onClick={() => {
                                  setMeetingCategoryUpdateData({
                                    id: item?.id,
                                    title: item?.title || "",
                                    meeting_location_category_id: item?.meetinglocationcategory?.id,
                                    status: item?.status,
                                  });
                                  setOpenUpdateModal(true); // Optionally open the update modal
                                }}
                              >
                                <Unicon icon={UilPen} size={20} />
                              </Button>

                              <Button
                                variant="phoenix-primary"
                                className="btn-icon me-2 rounded-1 bg-transparent"
                                onClick={() => {
                                  setItemId((prevData) => ({
                                    ...prevData,
                                    id: item?.id || 0, // Make sure to provide a default value if `item?.id` is undefined
                                    title: item?.title || ""
                                  }));
                                  setDeleteConfirmation(true)
                                }}
                              >
                                <Unicon icon={UilTrash} size={20} />
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
              <Col className="d-flex fs-9">
                <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                  {indexValueFirst} to {indexValueLast}
                  <span className="text-600"> items of </span>
                  {summary?.total}
                </p>
              </Col>
              <Col xs="auto">
                <CustomPagination
                  pageIndex={pageIndex}
                  totalPage={summary?.total_page}
                  activePage={Number(summary?.page)}
                  setPageIndex={setPageIndex}
                />
              </Col>
            </Row>
              :
              <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                <span className="text-600"> No record found !!</span>
              </p>
            }
          </PhoenixDocCard>
        </div>
      </AdvanceTableProvider >
      <Suspense fallback={<div>Loading...</div>}>
        {openMeetingLocationModal && (<AddMeetingLocationModal
          show={openMeetingLocationModal}
          handleClose={() => setOpenMeetingLocationModal(false)}
          setAddStatus={setAddStatus}
          meetingLocationCategory={meetingLocationCategory}
          toast={toast}
          navigation={navigation}
        />
        )}

        {openUpdateModal && (<UpdateMeetingLocationModal
          show={openUpdateModal}
          handleClose={() => setOpenUpdateModal(false)}
          setAddStatus={setAddStatus}
          meetingCategoryUpdateData={meetingCategoryUpdateData}
          meetingLocationCategory={meetingLocationCategory}
          toast={toast}
          navigation={navigation}
        />
        )}

        {showConfirmation && (<ConfirmationModal
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
          onConfirm={confirmStatusChange}
          text="Are you sure you want to change the status?"
        />
        )}

        {deleteConfirmation && (<ConfirmationModal
          show={deleteConfirmation}
          onHide={() => setDeleteConfirmation(false)}
          onConfirm={confirmDeleteMeetingLocation}
          text={`Are you sure you want to delete Meeting Location "${itemId.title}" ?`}
        />
        )}
      </Suspense>
      <ToastContainer />
    </div >
  );
};

export default MeetingLocationList;