export default function timeStampToDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  

  export function timeStampToDbDate(timestamp: Date): string {
    // Create a new Date object based on the provided timestamp
    const originalDate = new Date(timestamp);
  
    // Extract year, month, and day components
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(originalDate.getDate()).padStart(2, '0');
  
    // Assemble the formatted date string in the 'YYYY-MM-DD' format
    const formattedDate = `${year}-${month}-${day}`;
  
    // Return the formatted date string
    return formattedDate;
  }
  
//change date format yyyy-mm-dd to dd/mm/yyyy
  export function dbDateToDate(date: string): string {
    // Create a new Date object based on the provided timestamp
    const originalDate = new Date(date);
  
    // Extract year, month, and day components
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(originalDate.getDate()).padStart(2, '0');
  
    // Assemble the formatted date string in the 'YYYY-MM-DD' format
    const formattedDate = `${day}/${month}/${year}`;
  
    // Return the formatted date string
    return formattedDate;
  }
  

  export function dbDateWithTimeStamp(date:string){
    const currentDate = new Date(date);

// Get the date components
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because January is 0
const day = String(currentDate.getDate()).padStart(2, '0');

// Get the time components
const hours = String(currentDate.getHours()).padStart(2, '0');
const minutes = String(currentDate.getMinutes()).padStart(2, '0');
const seconds = String(currentDate.getSeconds()).padStart(2, '0');

// Form the date and time string
const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// Output: "2024-02-29 21:15:00"
 return formattedDate;

  }

  export function formatDueDate(date: Date): string  {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  
    return `${day}/${month}/${year} ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };