import React, { useState, useEffect, useRef } from 'react';
import { capitalize } from 'helpers/utils';
import { Dropdown, Nav } from 'react-bootstrap';
import { RouteItems, routes } from 'sitemap';
import TopNavMegaMenu from './TopNavMegaMenu';
import TopNavItem from './TopNavItem';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';

const NavbarTopNavCustom = () => {
  const { isUserAdmin } = useAuth();

  return (
    <Nav className="navbar-nav-top pb-lg-0 scrollbar custom_adminInner px-2" style={{maxHeight:"auto", height:"auto", overflow:"visible"}}>
      {routes.map((route) => {
        return (
          (isUserAdmin && route.label === 'Admin') ? (<NavbarTopNavItem route={route} key={route.label} />) : null
        );
      })}
    </Nav>
  );
};

const NavbarTopNavItem = ({ route }: { route: RouteItems }) => {
  const Icon = route.icon;
  const path = route.path;
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { breakpoints } = useBreakpoints();
  const navigation = useNavigate() as NavigateFunction;
  const { pathname } = useLocation();
  const { workSpace } = useAuth();
  const dropdownRef = useRef<HTMLLIElement>(null); // Change the type here to HTMLLIElement

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShow(false);
        setIsActive(false);
      }
    };

    if (show && !breakpoints.up('lg')) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, breakpoints]);

  const handleClick = () => {
  
    navigation(`/${workSpace?.alias}${path}`);
    if (!breakpoints.up('lg')) {
      setShow(!show);
      setIsActive(true);
   
    }
  };

  const handleToggle = () => {
    if (breakpoints.up('lg')) {
      setShow(!show);
    }
  };

  const handleMouseEnter = () => {
    if (breakpoints.up('lg')) {
      setShow(true);
    }
  };

  const handleMouseLeave = () => {
    if (breakpoints.up('lg')) {
      setShow(false);
    }
  };

  useEffect(() => {
    setIsActive(pathname === `/${workSpace?.alias}${path}`); // Check if current path matches this item's path
  }, [pathname, `/${workSpace?.alias}${path}`]);

  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [pathname]);



  return (
    <Dropdown
      as="li"
      show={show}
      className={`nav-item ${isActive ? 'active' : ''}`}
      key={route.label}
      autoClose="outside"
      onToggle={handleToggle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={dropdownRef}
    >
      <Dropdown.Toggle
        as="a"
        variant=""
        className="nav-link lh-1 d-flex align-items-center cursor-pointer"
        onClick={handleClick}
        style={{ backgroundColor: isActive ? '#CBD0DD' : '#F5F7FA' }}
      >
        <Icon className="me-2" size={16} />
        <span>
          {capitalize(
            route.horizontalNavLabel ? route.horizontalNavLabel : route.label
          )}
        </span>
      </Dropdown.Toggle>
      {route.megaMenu ? (
        <TopNavMegaMenu route={route} />
      ) : (
        <TopNavItem route={route} />
      )}
    </Dropdown>
  );
};

export default NavbarTopNavCustom;
