import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useAppContext } from "providers/AppProvider";
import { useEffect, useState } from "react";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import Unicon from 'components/base/Unicon';
import {UilCube,UilAbacus, UilMap } from '@iconscout/react-unicons';
import { Col, Card, Row } from "react-bootstrap";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import { getPageBreadCrumbs } from "services/getPageBreadCrumbs";


const ProjectOverView = () => {
  const {  workspace,  alias = "" } = useParams();
  const projectTitle = alias ? alias : "";
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("ProjectOverView")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
 
 
    let { state } = useLocation();
    const navigation = useNavigate() as NavigateFunction; // Explicitly define the type 


  const { config: { theme, navbarPosition }, setConfig, } = useAppContext();

  //set top navigation
  useEffect(() => {
    const value = "combo";
    const value2 = 'slim';
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });

  }, [])

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });
//update Breadcrumb
useEffect(() => {
  getPageBreadCrumbs({ pageName, workspace, setBreadCrumb, projectTitle });
}, [workspace,pageName, setBreadCrumb, projectTitle]);


  return (
    <>
      <div >
      
        <div >
        <PageBreadcrumb items={breadCrumb} navigation={navigation} />
          <div className="d-flex flex-wrap mb-4  justify-content-between">
            <h2 className="mb-0">
            <Unicon icon={UilMap} size={28}/><span className="me-3">{" Overview"}</span>
            </h2>
          </div>

          <div className="d-md-block d-block">
      
          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 row-cols-xxl-5">
            <Col className="mb-3">
            <Card className="px-3 py-2">
              <div className="vervieww">
                <p className="fs-8 title_overview">Total Action Items</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">56</h3>
                <Unicon icon={UilCube} size={28}/>
              </div>
            </Card>
            </Col>
            <Col className="mb-3">
            <Card className="px-3 py-2">
              <div className="vervieww">
                <p className="fs-8 title_overview">Total Pending Action Items</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">26</h3>
                <Unicon icon={UilCube} size={26}/>
              </div>
            </Card>
            </Col>
            <Col className="mb-3">
            <Card className="px-3 py-2">
              <div className="vervieww">
                <p className="fs-8 title_overview">Total Meetings </p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">20</h3>
                <Unicon icon={UilCube} size={26}/>
              </div>
            </Card>
            </Col>
            <Col className="mb-3">
            <Card className="px-3 py-2">
              <div className="vervieww">
                <p className="fs-8 title_overview">Total Story</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">56</h3>
                <Unicon icon={UilAbacus} size={26}/>
              </div>
            </Card>
            </Col>
            <Col className="mb-3">
            <Card className="px-3 py-2">
              <div className="vervieww">
                <p className="fs-8 title_overview">Total WBS</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center">
                <h3 className="fw-bold">56</h3>
                <Unicon icon={UilCube} size={26}/>
              </div>
            </Card>
            </Col>
          </Row>

          </div>

        </div>
      </div>
    </>

  );
};

export default ProjectOverView;
