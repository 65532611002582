// PrivateRoute.tsx
import { useAuth } from 'providers/AuthContext';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type PrivateRouteProps = {
  path?: string;
  element?: React.ReactNode;
};

export function PrivateRoute({ path, element }: PrivateRouteProps) {

  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) {
    // If not authenticated, redirect to the sign-in page
    return <Navigate to="/sign-in" />;
  } 
   else {
    // If authenticated and workspace is set, render the protected route content
    return <>{element}</>;
  }


}
