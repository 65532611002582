import PhoenixDocCard from "components/base/PhoenixDocCard";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import apiCall from "services/api";
import { getPageBreadCrumbs } from "services/getPageBreadCrumbs";
import { toast, ToastContainer } from 'react-toastify';
import { handleCutomError } from "services/handleCutomError";
import { Col } from "react-bootstrap";
import regexPatterns from "common/regexPatterns";

interface FormData {
    password: string;
    confirm_password: string;
}

interface FormError {
    password: '',
    confirm_password: string;
}


const UserProfile = () => {
    const navigation = useNavigate() as NavigateFunction;
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const initialBreadcrumb: PageBreadcrumbItem[] = [];
    const [pageName, setPageName] = useState("ProjectList")
    const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
    const { workspace } = useParams();
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState<FormData>({
        password: '',
        confirm_password: '',

    });
    const [error, setError] = useState<FormError>({
        password: '',
        confirm_password: '',
    });



    //update Breadcrumb
    useEffect(() => {
        getPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
    }, [workspace, pageName, setBreadCrumb]);


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent default form submission behavior
        e.stopPropagation();
        // Add the following line to set the form validation state
        setValidated(true);
        setLoading(true);

        if (           // Check if there are any errors before making the API call
            error.password ||
            error.confirm_password

        ) {
            setLoading(false);
            return;
        }


        if (  // Check if there are any errors without any change
            !formData.password.trim() ||
            !formData.confirm_password.trim()
        ) {
            setError((prevError: any) => ({
                ...prevError,
                password: !formData.password.trim() ? "Password is required." : "",
                confirm_password: !formData.confirm_password ? "Confirm Password is required." : ""
            }));
            setLoading(false);
            return;
        }

        const formDataSubmit = new FormData();

        formDataSubmit.append('password', formData?.password);
        formDataSubmit.append('confirm_password', formData?.confirm_password);


        try {
            const response = await apiCall({
                url: `change-password`,
                method: 'POST',
                data: formDataSubmit,
                headers: {
                    'x-access-token': userTkn,
                    'workspace': workSpaceTkn
                },
            });


            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;

                    // Dynamically set errors based on the response
                    Object.keys(errors).forEach((key) => {
                        const errorMessages = errors[key];
                        const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                        // Set the error state for the corresponding field
                        setError((prevData) => ({
                            ...prevData,
                            [key]: firstErrorMessage,
                        }));
                    });
                    setLoading(false);
                } else {
                    toast.success(response.data.message, {
                        position: "top-center"
                    });
                    setLoading(false);
                    //   navigation(`/${workspace}/project/${alias}/setting`)
                }

            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                // Display a generic error message to the user
                toast.error('An unexpected error occurred', {
                    position: "top-center"
                });
            }
            setLoading(false);
        }
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
    
        let errorMessage = "";
    
        // Validation for first name

        // Validation for password
        if (name === "password") {
          if (!value.trim()) {
            errorMessage = "Password is required.";
          } else if (!regexPatterns.passwordRedex3.test(value)) {
            errorMessage = "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character (!, @, #, $, %, ^, &, *, (, ), [, ], -, _), and be between 6 and 14 characters long.";
          } else if (value !== formData.confirm_password && formData.confirm_password !== "") {
            errorMessage = "Passwords and Repeat Password do not match.";
          }
        }
    
        // Validation for matching passwords
        if (name === "confirm_password") {
          if (value !== formData.password) {
            errorMessage = "Passwords and Repeat Password do not match.";
          }
        }
    
    
        setError({
          ...error,
          [name]: errorMessage,
        });
      };

      const handleInputChange = (
        e: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ) => {
        const { name, value } = e.target;
    
        setError({
            ...error,
            [name]: '',
          });
        setFormData({
            ...formData,
            [name]: value,
          });
      };
    
    return (
        <>
            <PageBreadcrumb items={breadCrumb} navigation={navigation} />
            <div className="d-flex flex-wrap mb-4 align-items-center">
                <h2 className="mb-0">
                    <span className="me-3">User Profile </span>{" "}
                </h2>

            </div>
            <PhoenixDocCard className="p-sm-4 p-2 mt-3 ">
                <h6 className="mb-0">
                    <span className="me-3">Password Reset</span>{" "}
                </h6>
                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                    <Modal.Body className="pt-4 pb-2 px-4">
                        <Row >
                            <Col md={4} className="mb-3">
                                <Form.Group className=" text-start">
                                    <FloatingLabel
                                        controlId="floatingInputGrid"
                                        label={`Password *`}
                                    >
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            isInvalid={!!error.password}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error?.password}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Group className=" text-start">
                                    <FloatingLabel
                                        controlId="floatingInputGrid"
                                        label={`Re Password *`}
                                    >
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Re Password"
                                            name="confirm_password"
                                            value={formData.confirm_password}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            isInvalid={!!error.confirm_password}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error?.confirm_password}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col md={4} className="mb-3 d-flex justify-content-center align-items-center">
                                <Button
                                    variant="phoenix-primary"
                                    size="sm"
                                    className="px-9 fs-9  my-0 btn btn-outline-primary bg-transparent"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </PhoenixDocCard>
            <ToastContainer />
        </>
    )
}

export default UserProfile;