const Constants = {
  BASE_URL: "https://api.spider.triline.in/",  //production master
  BASE_URL2:"https://api.spider.tlitech.net/",  // beta

  title: "TITLE",
  category: "CATEGORY",
  phase: "PHASE",
  phases:"Phase",
  new_phase:"New Phase",
  client: "CLIENT",
  last_active: "LAST ACTIVE",
  editable: "EDITABLE",
  status: "STATUS",
  new_project: "New Project",
  create_project: "Add New Projects",
  project_title: "Project Name",
  start_date: "Start Date",
  created_date: "Created Date",
  created_by:'Created By',
  end_date: "End date",
  clients: "Clients",
  entity:"Entity",
  new_entity: "New Entity",
  client_name: "Entity name",
  user_name: "User name",
  action: "Action",
  links: "Links",
  watching: "Watching",
  create_user: "Create user",
  tags:"Tags",
  new_priority:'New Priority',
  priority:"Priority",
  color:"Color",
  //admin
  project: "Project",
  projects: "Projects",
  parent_project: "Parent Project",
  user: "Users Name",
  full_name: "Full Name",
  nick_name: "Nick Name",
  meta: "Meta",
  project_category: "Project Category",
  new_category: "New Category",
  email: "email Id",
  user_password: "User password",
  first_name: "First Name",
  last_name: "Last name",
  new_user: "New User",
  existing_user:"Add Existing user",
  short_name: "Short Name",
  designation: "Designation",
  org:"ORG",
  orderd_by: "Orderd By",
  new_designation: "New Designation",
  name:"Name",
  description:"Description",
  designation_name:"Designation Name",
  user_project_permissions: "User Project Permissions",
  user_permission:"User Permission",
  category_name:  "Category Name"
};

export default Constants;
