import React from 'react';
import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface CustomPaginationProps {
  pageIndex: number | null;
  totalPage: number;
  activePage: number;
  setPageIndex: (pageIndex: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  pageIndex,
  totalPage,
  activePage,
  setPageIndex,
}) => {
  const handlePrevClick = () => {
    if (pageIndex !== null && pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (pageIndex !== null && pageIndex < totalPage) {
      setPageIndex(pageIndex + 1);
    }
  };

  const handlePageClick = (pageNumber: number) => {
    setPageIndex(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];

    // Render first 5 pages
    for (let i = 1; i <= Math.min(totalPage, 5); i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={activePage === i}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Render ellipsis if more than 10 pages
    if (totalPage > 10) {
      items.push(<Pagination.Ellipsis key="ellipsis" />);
    }

    // Render last 2 pages
    for (let i = Math.max(totalPage - 1, 6); i <= totalPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={activePage === i}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination className="mb-0 justify-content-center">
      <Pagination.Prev disabled={pageIndex === 1} onClick={handlePrevClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Pagination.Prev>
      {renderPaginationItems()}
      <Pagination.Next disabled={pageIndex === totalPage} onClick={handleNextClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Pagination.Next>
    </Pagination>
  );
};

export default CustomPagination;
