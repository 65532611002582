
export const handleSpecialFilters = (itemName: string, setPageFilters: React.Dispatch<React.SetStateAction<any>>, loggedInUser: any) => {
    
    switch (itemName) {
        case "all":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: String(loggedInUser?.id),
                assign_by: String(loggedInUser?.id),
                auditor_id: String(loggedInUser?.id),
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                is_approved: '',
                flag: ''
            }));
            return;
        case "myactionpoints":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                assign_to: '',
                assign_by: String(loggedInUser?.id),
                auditor_id: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                is_approved: '',
                flag: ''
            }));
            return;
        case "assignedtomee":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                assign_to: String(loggedInUser?.id),
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: ''
            }));
            return;
        case "auditor":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                auditor_id: String(loggedInUser?.id),
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                assign_to: '',
                is_approved: '',
                flag: ''
            }));
            return;
        case "open":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: "open",
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: ''
            }));
            return;
        case "completed":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: String(loggedInUser?.id),
                due_date_range: '',
                finish_date_range: '',
                action_point_status: "completed",
                assign_by: String(loggedInUser?.id),
                auditor_id: String(loggedInUser?.id),
                is_approved: '',
                flag: ''
            }));
            return;
        case "decline":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: "declined",
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: ''
            }));
            return;

        case "pending":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: "pending",
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: ''
            }));
            return;

        case "reopen":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: "reopen",
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: ''
            }));
            return;
        case "approved":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                auditor_id: '',
                is_approved: '1',
                flag: ''
            }));
            return;
        case "notapproved":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                auditor_id: '',
                is_approved: '0',
                flag: ''
            }));
            return;
        case "late":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: 'late'
            }));
            return;
        case "ontime":
            // Set the assign_to, assign_by, auditor_id as loggedin user
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: 'ontime'
            }));
            return;
        case "today":
            const today = new Date();
            const formattedDate = today.toISOString().slice(0,10);
            const dateRange = `${formattedDate} 00:00:00 - ${formattedDate} 23:59:59`;
            
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: dateRange,
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: ''
            }));
            return;
        default:
            setPageFilters((prevData: any) => ({
                ...prevData,
                shorting: 'when_ascending',
                assign_to: '',
                due_date_range: '',
                finish_date_range: '',
                action_point_status: '',
                assign_by: '',
                auditor_id: '',
                is_approved: '',
                flag: ''
            }));
            return;
    }
};