import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/img/icons/logo-sign.png";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { useGoogleLogin } from '@react-oauth/google';
import { useMsal } from "@azure/msal-react";

// import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import regexPatterns from "common/regexPatterns";
import { BrowserAuthError } from "@azure/msal-browser";
import { toast, ToastContainer } from 'react-toastify';
import { EyeIcon2 } from "components/common/EyeIcon";
import { useVersion } from "providers/VersionProvider";
import VersionType from "components/custom/VersionType";

// github user authentication
const CLIENT_ID = "bee789cf9c68d1382ba2"
const SECRET_ID = "371383eb430284af215bf82e08333bbf5a5234f4";

declare global {
  interface Window {
    google: any; // Define the 'google' variable globally with any type
  }
}
interface GoogleData {
  sub: string;
  email: string;
  // Add other properties if needed
}





const SignInForm = ({ layout }: { layout: "simple" | "card" | "split" }) => {

  const { version } = useVersion()
  const [validated, setValidated] = useState<boolean>(false);
  const { isAuthenticated, signIn, signInGoogle, signInMicroSoft, workSpace } = useAuth();

  const [commonError, setCommonError] = useState<any>("");
  const [googleAuthError, setGoogleAuthError] = useState<any>("");
  const [microSoftAuthError, setMicroSoftAuthError] = useState<any>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  })
  const [error, setError] = useState({
    email: "",
    password: ""
  })

  const navigate = useNavigate();
  const { instance } = useMsal()


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setError({
      ...error,
      [name]: '',
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";

    // Validation for email
    if (name === "email") {
      if (!value.trim()) {
        errorMessage = "Email is required.";
      } else if (!regexPatterns.emailRegex.test(value)) {
        errorMessage = "Please enter a valid email address.";
      }
    }

    // Validation for password
    if (name === "password") {
      if (!value.trim()) {
        errorMessage = "Password is required.";
      } 
      else if (value.length < 6 ) {
        errorMessage = "Please enter correct password.";
      }
    }


    setError({
      ...error,
      [name]: errorMessage,
    });

  };


  // Use a useEffect to handle navigation once isAuthenticated changes
  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/${workSpace?.alias}`); // Navigate to the "ecommerce" page
    }
  }, [isAuthenticated, workSpace]);




  useEffect(() => {
    const queryString = window.location.search;
    const urlPrams = new URLSearchParams(queryString)
    const codePrams = urlPrams.get("code");
  }, [])

  const handleError = (error: any) => {


    const errors = error?.errors;

    // Dynamically set errors based on the response
    Object.keys(errors).forEach((key) => {
      const errorMessages = errors[key];
      const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message

      // Set the error state for the corresponding field
      setError((prevData) => ({
        ...prevData,
        [key]: firstErrorMessage,
      }));
    });
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    } else if (error.email ||
      error.password) {
      return;
    }
    else {
      setLoading(true);
      try {
        // Call the signIn function from useAuth to perform authentication
        let value = await signIn(formData.email, formData.password, setCommonError, handleError);

        // Perform additional actions after successful sign-in if needed


      } finally {
        // Set loading back to false when the API call is complete
        setLoading(false);
      }
    }
  };


  useEffect(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 150000);
  }, [commonError, googleAuthError]);





  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const token = tokenResponse.access_token;
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
      const result = userInfo.data;
      signInGoogle(
        "google",
        result.sub,
        result.email,
        setGoogleAuthError
      );
    }

  });
  const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read']
  };
  const loginMicrosoft = () => {
    instance.loginPopup(loginRequest)
      .then(response => {

        signInMicroSoft(
          "microsoft",
          response?.account?.username,
          response?.account?.homeAccountId,
          setMicroSoftAuthError
        );
        // Handle successful login
      })
      .catch((error: any) => {
        if (error instanceof BrowserAuthError && error.errorMessage.indexOf('interaction_in_progress') > -1) {
          // Handle interaction_in_progress error
          console.error('Interaction in progress. Please wait or close the existing interaction.');
          toast.error('Interaction in progress. Please wait or close the existing interaction.', {
            position: "top-center"
          });
        } else {
          // Handle other errors

          toast.error(error.errorMessage, {
            position: "top-center"
          });
        }
      });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="text-center mb-3">
        <img src={logo} alt="" width={160} className="rounded-2  mt-3" />
        {/* <VersionType /> */}
      </div>
      <div className="mb-2 text-center">
        <h3 className="text-1000">Login</h3>
        <p className="text-700">Get access to your account</p>
      </div>
      <div className="my-4">
        <Button
          variant="phoenix-secondary"
          className="w-100 mb-3"
          onClick={() => {
            loginGoogle()
          }}
          startIcon={
            <FontAwesomeIcon
              icon={faGoogle}
              className="text-danger me-2 fs-9"
            />
          }
        >Google</Button>
        {/* <Button
          variant="phoenix-secondary"
          className="w-100 mb-3 px-0"
          id="signInDev"
          startIcon={
            <FontAwesomeIcon
              icon={faMicrosoft}
              className="text-primary me-2 fs-9"
            />
          }
          onClick={() => loginMicrosoft()}
        >
          Microsoft
        </Button> */}
      </div>
      {googleAuthError && googleAuthError?.errors?.email && (
        <div
          style={{
            marginBottom: "0.25rem",
            color: "#ed2000",
            fontSize: "75%",
            paddingLeft: "1rem",
          }}
        >
          {googleAuthError?.errors?.email[0]}
        </div>
      )}
      {microSoftAuthError && microSoftAuthError?.errors?.email && (
        <div
          style={{
            marginBottom: "0.25rem",
            color: "#ed2000",
            fontSize: "75%",
            paddingLeft: "1rem",
          }}
        >
          {microSoftAuthError?.errors?.email[0]}
        </div>
      )}
      <div className="position-relative">
        <hr className="bg-200 mt-4 mb-5" />
        <div className="divider-content-center">or </div>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="email">Email address</Form.Label>
            <div className="form-icon-container">
              <Form.Control
                required
                id="email"
                type="email"
                name="email"
                className="form-icon-input"
                placeholder="name@example.com"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                isInvalid={error.email !== ""}
              />
              <Form.Control.Feedback type="invalid">
                {error?.email && error?.email }
              </Form.Control.Feedback>
              <FontAwesomeIcon
                icon={faUser}
                className="text-600 fs-9 form-icon"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="password">Password</Form.Label>
            <div className="form-icon-container">
              <Form.Control
                required
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                className="form-icon-input"
                placeholder="Password"
                value={formData?.password}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                minLength={6}
                isInvalid={error.password !== ""}
              />
              <EyeIcon2 togglePasswordVisibility={togglePasswordVisibility} showPassword={showPassword} />
              <Form.Control.Feedback type="invalid">
                {error?.password && error?.password}
              </Form.Control.Feedback>
              <FontAwesomeIcon
                icon={faKey}
                className="text-600 fs-9  form-icon"
              />

            </div>
          </Form.Group>
          <Row className="flex-between-center mb-3 px-0">
            <Col xs="auto" className="px-0">
            </Col>
            <Col xs="auto" className="px-0">
              <Link to={`/forgot-password`} className="fs-9 fw-semi-bold">
                Forgot Password?
              </Link>
            </Col>
          </Row>
          {showError && commonError?.errors?.email && (
            <div
              style={{
                marginBottom: "0.25rem",
                color: "#ed2000",
                fontSize: "75%",
                paddingLeft: "1rem",
              }}
            >
              {commonError?.error_code && `status: ${commonError?.error_code}`}
            </div>
          )}

          {showError && commonError?.errors?.password && (
            <div
              style={{
                marginBottom: "0.25rem",
                color: "#ed2000",
                fontSize: "75%",
                paddingLeft: "1rem",
              }}
            >
              {commonError?.error_code && `status: ${commonError?.error_code} `}
            </div>
          )}
          <Form.Group className="mb-3">
            <Button
              variant="outline-primary"
              className="w-100 "
              type="submit"
              disabled={loading} // Disable the button when loading is true
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Signing In...
                </>
              ) : (
                "Login"
              )}
            </Button>
          </Form.Group>
        </Row>
      </Form>

      <div className="mb-4"></div>
      <ToastContainer />
    </>
  );
};

export default SignInForm;
