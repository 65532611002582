import React, { useState } from 'react';

interface SpecialFilters {
  handleSpecialFilters:any
}

function ActionsPointsSpecialFilters({handleSpecialFilters}:SpecialFilters) {
    const [selectedItem, setSelectedItem] = useState('');

    const handleClick = (itemName: string) => {
            setSelectedItem(itemName);
            handleSpecialFilters(itemName)
    };
  

    return (
        <>
            <div className="navbar_actions mb-3 mx-0">
                <div className='d-flex gap-5 mobileSlide'>
                    <div className='d-flex align-items-center gap-4 border-rightSet'>
                      <div onClick={() =>{handleClick('all')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'all' ? 'selected-actionpoint-filters' : ''}`} >All</div>
                      <div onClick={() => {handleClick('myactionpoints')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'myactionpoints' ? 'selected-actionpoint-filters' : ''}`}>My Action Items</div>
                      <div onClick={() => {handleClick('assignedtomee')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'assignedtomee' ? 'selected-actionpoint-filters' : ''}`}>Assigned To Me</div>
                      <div onClick={() => {handleClick('auditor')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'auditor' ? 'selected-actionpoint-filters' : ''}`}>Auditor</div>
                    </div>
                    <div className='d-flex align-items-center gap-4 border-rightSet '>
                      <div onClick={() => {handleClick('open')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'open' ? 'selected-actionpoint-filters' : ''}`}>Open</div>
                      <div onClick={() => {handleClick('completed')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'completed' ? 'selected-actionpoint-filters' : ''}`}>Completed</div>
                      <div onClick={() => {handleClick('pending')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'pending' ? 'selected-actionpoint-filters' : ''}`}><span >Pending</span></div>
                      <div onClick={() => {handleClick('reopen')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'reopen' ? 'selected-actionpoint-filters' : ''}`}>Reopen</div>
                      <div onClick={() => {handleClick('decline')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'decline' ? 'selected-actionpoint-filters' : ''}`}>Decline</div>
                    </div>
                    <div className='d-flex align-items-center gap-4 border-rightSet '>
                      <div onClick={() => {handleClick('approved')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'approved' ? 'selected-actionpoint-filters' : ''}`}>Approved</div>
                      <div onClick={() => {handleClick('notapproved')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'notapproved' ? 'selected-actionpoint-filters' : ''}`}>Not Approved</div>
                      {/* <div onClick={() => {handleClick('repeat')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'repeat' ? 'selected-actionpoint-filters' : ''}`}>Repeat</div> */}
                    </div>
                    <div className='d-flex align-items-center gap-4 '>
                      <div onClick={() => {handleClick('late')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'late' ? 'selected-actionpoint-filters' : ''}`}>Late</div>
                      <div onClick={() => {handleClick('ontime')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'ontime' ? 'selected-actionpoint-filters' : ''}`}>On-Time</div>
                      <div onClick={() => {handleClick('today')}} className={`fs-9 text-decoration-none fw-bold  actionpoint-filters ${selectedItem === 'today' ? 'selected-actionpoint-filters' : ''}`}>Today</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ActionsPointsSpecialFilters;
