import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FloatingLabel, Form } from 'react-bootstrap';

// Define the type for the page filters state
type PageFiltersState = {
    status: string;
    titleSearch: string;
  };
  
  type SearchInputProps = {
    pageFilters: PageFiltersState;
    handleSearchInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSearchInputBlur: (action: string) => void;
    handlePageFilters: (action: string) => void;
    handleOnBlur: React.FocusEventHandler<HTMLInputElement>;
    title: string
  };
  
const SearchInput: React.FC<SearchInputProps> = ({
  pageFilters,
  handleSearchInputChange,
  handleOnBlur,
  handleSearchInputBlur,
  handlePageFilters,
  title
}) => {
  return (
    <FloatingLabel controlId="floatingInputGrid" label={<><FontAwesomeIcon icon={faSearch} /> <span>{title}</span></>}>
      <Form.Control
        type="text"
        placeholder="Project title"
        onChange={handleSearchInputChange}
        onBlur={handleOnBlur}
        value={pageFilters.titleSearch}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearchInputBlur("search");
          }
        }}
      />
      {pageFilters.titleSearch && (
        <FontAwesomeIcon
          icon={faTimes}
          className="position-absolute end-0 top-50 translate-middle-y me-2"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handlePageFilters("")
            handleSearchInputBlur("remove");
          }}
        />
      )}
    </FloatingLabel>
  );
};

export default SearchInput;
