import { useLocation,Link } from "react-router-dom";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useAppContext } from "providers/AppProvider";
import { useEffect } from "react";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import Unicon from 'components/base/Unicon';
import { UilCube, UilFilePlusAlt, UilPlus } from '@iconscout/react-unicons';
import {  Col, Card, Row} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
const ProjectInfo = () => {

  const { config: { theme, navbarPosition }, setConfig, } = useAppContext();

  //set top navigation
  useEffect(() => {
    const value = "combo";
    const value2 = 'slim';
    
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });

  }, [])

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  return (
    <>
      <div >

        <div >
          <div className="d-flex flex-wrap mb-4  justify-content-between">
            <h2 className="mb-0">
            <Unicon icon={UilCube} size={28}/><span className="me-3">{" Project Info"}</span>
            </h2>
            <div className="d-flex align-items-center gap-2">
            <Link className="btn btn-outline-primary px-4 " to={`project-info-admin`} >
                 Admin            
            </Link>
            <Link className="btn btn-outline-primary px-4 " to="" >
            <Unicon icon={UilFilePlusAlt} size={17}/> Files           
            </Link>
            <Link className="btn btn-outline-primary px-4 " to="" >
            <Unicon icon={UilPlus} size={17}/> Link          
            </Link>
            </div>
        
          </div>

          <div className="d-md-block d-block">
      
         <Row>
            <Col lg={9}>
                <div className="Prject_infoDetails mb-3">
                    <h3 className="colorSet_heading mb-2">WIKI</h3>
                    <p className="fs-8 mb-0">Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.</p>
                    <div className="updatedate">
                    <div className="fs-8 mt-2">
                      {"Updated date: "}
                      <span className="fw-semi-bold">
                      10 Jan 2024, 12:47 PM
                      </span>
                    </div>
                    </div>
                </div>
                <div className="Prject_infoDetails">
                    <h4 className="colorSet_heading mb-2">Dummy</h4>
                    <p className="fs-8 mb-0">Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.</p>
                    <div className="updatedate">
                    <div className="fs-8 mt-2">
                      {"Updated date: "}
                      <span className="fw-semi-bold">
                      10 Jan 2024, 12:47 PM
                      </span>
                    </div>
                    </div>
                </div>
            </Col>
            <Col lg={3}>
            <Card className="projectInfo_card">
                <Row className="pb-4">
                    <Col xs={12} className="mb-3">
                        <h4>Dummy Group 1</h4>
                    </Col>
                    <Row className="pb-2">
                    <Col md={5}>Translation:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">English, Hindi, German</p>
                    </Col>
                    </Row>
                    <Row className="pb-2">
                    <Col md={5}>Denouncing:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Consequences</p>
                    </Col>
                    </Row>
                    <Row className="pb-2">
                    <Col md={5}>Necessitatibus:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Voluptatibus</p>
                    </Col>
                    </Row>
                    <Row className="pb-2">
                    <Col md={5}>Project Status:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Designing</p>
                    </Col>
                    </Row>
                 
                </Row>
                <Row className="pb-4">
                    <Col xs={12} className="mb-3">
                        <h4>Dummy Group 1</h4>
                    </Col>
                    <Row className="pb-2">
                    <Col md={5}>Translation:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">English, Hindi, German</p>
                    </Col>
                    </Row>
                    <Row className="pb-2">
                    <Col md={5}>Denouncing:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Consequences</p>
                    </Col>
                    </Row>
                    <Row className="pb-2">
                    <Col md={5}>Necessitatibus:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Voluptatibus</p>
                    </Col>
                    </Row>
                    <Row className="pb-2">
                    <Col md={5}>Project Status:</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">Designing</p>
                    </Col>
                    </Row>
                 
                </Row>
                <Row className="pb-4">
                    <Col xs={12} className="mb-3">
                        <h4>Files</h4>
                    </Col>
                    <Row className="pb-2">
                    <Col md={5}>1.filesss</Col>
                    <Col md={7}>
                        <p className="mb-0 fs-8 fw-bold">English, Hindi, German</p>
                    </Col>
                    </Row>
               
                 
                </Row>
            </Card>
            </Col>
         </Row>
         <Row className="mt-3">
            <Col md={12} className="mb-3">
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="bg-customs rounded-2 border-0">
             <Accordion.Header className="fs-7">Contents</Accordion.Header>
               <Accordion.Body className="pt-0">
             <strong>This is the first item's accordion body.</strong>
      It is shown by default, until the collapse plugin adds the appropriate classes
      that we use to style each element. These classes control the overall appearance,
      as well as the showing and hiding via CSS transitions. You can modify any of this
      with custom CSS or overriding our default variables. It's also worth noting that
      just about any HTML can go within the <code>accordion-body</code>, though the
      transition does limit overflow.
             </Accordion.Body>
              </Accordion.Item>

</Accordion>

            </Col>
            <Col md={12}>
            <div className="Prject_infoDetails mb-3">
                    <h4 className="colorSet_heading mb-2">Dummy</h4>
                    <p className="fs-8 mb-0">Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.Oxfam India and YLAC have been working together to set up Equality Clubs in schools across India. The aim is to raise consciousness and appreciation of one’s own privilege, and inspire students to take action.</p>
                    <div className="updatedate">
                    <div className="fs-8 mt-2">
                      {"Updated date: "}
                      <span className="fw-semi-bold">
                      10 Jan 2024, 12:47 PM
                      </span>
                    </div>
                    </div>
                </div>
            </Col>
         </Row>
          </div>
        </div>
      </div>
    </>

  );
};

export default ProjectInfo;
