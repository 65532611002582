import { useState, useEffect, ChangeEvent } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { Col, FloatingLabel, Form, Placeholder, Row, Table, Nav, Tab, } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import Avatar from "components/base/Avatar";
import apiCall from "../../services/api"; // Adjust the path based on your project structure
import Unicon from 'components/base/Unicon';
import { UilToggleOff, UilToggleOn } from '@iconscout/react-unicons';
import { dbDateToDate } from "common/timeStampToDate";
import { useParams } from 'react-router-dom';
import calculateSerialNumber from "common/calculateSerialNumber";
import { getAdminPageBreadCrumbs } from "services/getAdminPageBreadCrumbs";
import { toast, ToastContainer } from 'react-toastify';
import { handleCutomError } from "services/handleCutomError";

// Assuming you have a type for your data objects
type Projects = {
  id: number;
  name: string;
  short_name: string | null;
  alias: string;
  status: number;
  start_date: string;
  first_name: string;
  last_name: string;
  title: string;
  serialNumber: number;

};

type Users = {
  id: number;
  name: string;
  short_name: string | null;
  alias: string;
  status: number;
  start_date: string;
  first_name: string;
  last_name: string;
  title: string;
  serialNumber: number;
  color_code: string;

};

type Entity = {
  id: number;
  alias: string;
  name: string;
  short_name: string | null;
  status: number
}


const AdminEntityListView = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("AdminEntityListView")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { alias, workspace } = useParams();
  // Now 'user' is accessible in your component
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [entity, setEntity] = useState<Entity | null>();
  const [projects, setProjects] = useState<Projects[] | null>(null);
  const [users, setUsers] = useState<Users[] | null>(null);
  const [pageIndex, setPageIndex] = useState<number | null>(1);

  //for filters
  const [titleSearch, setTitleSearch] = useState<string | null>("");
  const [editableFilter, setEditableFilter] = useState<string | null>("");
  const [addStatus, setAddStatus] = useState<boolean>(false);
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type

  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, setBreadCrumb]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true
      try {
        const response = await apiCall({
          url: `admin/entity/${alias}/view`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });
        setEntity(response?.data)
        // Example of parallel requests using Promise.all with apiCall
        const [projectListResponse, userListResponse] = await Promise.all([
          apiCall({
            url: `admin/entity/${alias}/projects`,
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              'workspace': workSpaceTkn
            },
          }),
          apiCall({
            url: `admin/entity/${alias}/users`,
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              'workspace': workSpaceTkn
            },
          }),
        ]);

        if (projectListResponse?.status === 200) {
          // Handle the response data if needed
          const updatedProjects = projectListResponse.data.entityprojects.map((projects: any, index: any) => ({
            ...projects,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
          }));
          setAddStatus(false)
          setProjects(updatedProjects);
        }
        if (userListResponse?.status === 200) {
          // Handle the response data if needed
          const updatedUsers = userListResponse.data.entityusers.map((users: any, index: any) => ({
            ...users,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
          }));
          setUsers(updatedUsers);
        }

      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [userTkn, editableFilter, addStatus]);



  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: projects || [],
    columns: projectsTablecolumns,
    pageSize: 2,
    pagination: true,
    sortable: true,
    // selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    // table.setGlobalFilter(e.target.value || undefined);
    setTitleSearch(e.target.value);
  };

  const handleSearchInputBlur = () => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiCall({
          url: `admin/client?page=${pageIndex}&name=${titleSearch}`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          // setData(response?.data?.clients?.values);
          // setSummary(response.data.clients.summary);
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  };



  const getStatusIcon = (status: number, alias: string) => {
    if (status === 1) return <Unicon icon={UilToggleOn} size={21} color="" fill="#8FC644" />
    else if (status === 0) return <Unicon icon={UilToggleOff} size={21} color="" fill="#6E7891" />
    else return <div></div>
  }

  const getStatusIconUser = (status: number) => {
    if (status === 10) return <Unicon icon={UilToggleOn} size={21} color="" fill="#8FC644" />
    else if (status === 9) return <Unicon icon={UilToggleOff} size={21} color="" fill="#6E7891" />
    else return <div></div>
  }

  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItemsAdminClientView} navigation={navigation} /> */}
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4  justify-content-between">
          <h2 className="mb-0">
            {<span className="me-3">{entity?.name}</span>}
          </h2>

        </div>
        <Row>
          <Col xl={12} xxl={12} className="mb-1 ">
            <Tab.Container defaultActiveKey="clientprojects">
              <Nav variant="underline" className="">
                <Nav.Item className="under_line">
                  <Nav.Link eventKey="clientprojects">Project</Nav.Link>
                </Nav.Item>
                <Nav.Item className="under_line">
                  <Nav.Link eventKey="users">Users</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content style={{ marginTop: "-2px" }}>
                <Tab.Pane eventKey="clientprojects">
                  <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1'>
                    <Row className="mb-3 d-flex justify-content-between">

                      <Col xs={12} sm="auto">
                        {" "}
                        <div className="d-flex flex-wrap gap-3 ">
                          <FloatingLabel
                            controlId="floatingInputGrid"
                            label={
                              <>
                                <FontAwesomeIcon icon={faSearch} /> <span>Title</span>
                              </>
                            }
                          >
                            <Form.Control
                              type="text"
                              placeholder="Project title"
                              onChange={handleSearchInputChange}
                              onBlur={handleSearchInputBlur}
                            />
                          </FloatingLabel>
                          <div className="scrollbar overflow-hidden-y d-flex gap-2">
                            {/* <FilterButtonGroup menus={filterMenus} /> */}
                            <FloatingLabel controlId="lead-woner" label="Status">
                              <Form.Select
                                onChange={(e) => {
                                  setEditableFilter(e.target.value);
                                }}
                              >
                                <option value="">All</option>
                                <option value="1">Enable</option>
                                <option value="0">Disable</option>
                              </Form.Select>
                            </FloatingLabel>
                          </div>
                          <div className="scrollbar overflow-hidden-y d-flex gap-2">
                            {/* <FilterButtonGroup menus={filterMenus} /> */}
                            <FloatingLabel controlId="lead-woner" label="Created By">
                              <Form.Select
                                onChange={(e) => {
                                  setEditableFilter(e.target.value);
                                }}
                              >
                                <option value="">Select</option>
                                <option value="1">Enable</option>
                                <option value="0">Disable</option>
                              </Form.Select>
                            </FloatingLabel>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Table
                      className="phoenix-table fs-9 project-table border rounded"
                      size="sm"
                      striped
                      hover
                      responsive
                    >
                      <thead>
                        <tr id={"a1"}>
                          <th style={{ width: "2%", maxWidth: "20px", padding: "16px 0px" }}>#</th>
                          <th className="px-1" style={{ width: "22%", minWidth: "100px" }} >{Constants.project_title}</th>
                          <th className="px-1" style={{ width: "12%", minWidth: "100px" }}> {Constants.created_by} </th>
                          <th className="px-1" style={{ width: "12%", minWidth: "100px" }}> {Constants.start_date} </th>
                          <th className="px-1" style={{ width: "7%", minWidth: "100px" }}> {Constants.status}</th>

                        </tr>
                      </thead>
                      <tbody>
                        {projects &&
                          projects.map((item: Projects, index: number) => {
                            let changeDateFormat = dbDateToDate(item?.start_date)
                            return (
                              <tr key={item?.id || index}>
                                <td>
                                  {loading ? (
                                    <Placeholder xs={7} />
                                  ) : (
                                    item?.serialNumber
                                  )}
                                </td>
                                <td>

                                  {loading ? (
                                    <Placeholder xs={7} />
                                  ) : (

                                    <div className="d-flex align-items-center">
                                      <div className="avatar_width">
                                        <Avatar size="m" variant="name" className="me-1">
                                          {item?.title?.slice(0, 2)}
                                        </Avatar>
                                      </div>
                                      <Link
                                        to="#"
                                        className="text-decoration-none text-black fw-semi-bold pe-none fs-8"
                                      >
                                        {item?.title}
                                      </Link>
                                    </div>

                                  )}
                                </td>
                                <td>



                                </td>
                                <td>
                                  {changeDateFormat}


                                </td>
                                <td className="">
                                  {loading ? (
                                    <Placeholder xs={7} />
                                  ) :
                                    (getStatusIcon(item.status, item.alias))
                                  }
                                </td>

                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </PhoenixDocCard>
                </Tab.Pane>
                <Tab.Pane eventKey="users">
                  <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1' >
                    <Row className="mb-3 d-flex justify-content-between">

                      <Col xs={12} sm="auto">
                        {" "}
                        <div className="d-flex flex-wrap gap-3 ">
                          <FloatingLabel
                            controlId="floatingInputGrid"
                            label={
                              <>
                                <FontAwesomeIcon icon={faSearch} /> <span>Title</span>
                              </>
                            }
                          >
                            <Form.Control
                              type="text"
                              placeholder="Project title"
                              onChange={handleSearchInputChange}
                              onBlur={handleSearchInputBlur}
                            />
                          </FloatingLabel>
                          <div className="scrollbar overflow-hidden-y d-flex gap-2">
                            {/* <FilterButtonGroup menus={filterMenus} /> */}
                            <FloatingLabel controlId="lead-woner" label="Status">
                              <Form.Select
                                onChange={(e) => {
                                  setEditableFilter(e.target.value);
                                }}
                              >
                                <option value="">All</option>
                                <option value="1">Enable</option>
                                <option value="0">Disable</option>
                              </Form.Select>
                            </FloatingLabel>
                          </div>
                        </div>
                      </Col>

                    </Row>

                    <Table
                      className="phoenix-table fs-9 project-table border rounded"
                      size="sm"
                      striped
                      hover
                      responsive
                    >
                      <thead>
                        <tr id={"a1"}>
                          <th style={{ width: "1%", maxWidth: "20px", padding: "16px 0px" }}>#</th>
                          <th className="px-1" style={{ width: "22%", minWidth: "100px" }} >{Constants.user}</th>
                          <th className="px-1" style={{ width: "7%", minWidth: "100px" }}>{Constants.start_date}</th>
                          <th className="px-1" style={{ width: "7%", minWidth: "100px" }}> {Constants.status}</th>

                        </tr>
                      </thead>
                      <tbody>
                        {users &&
                          users.map((item: Users, index: number) => {
                            return (
                              <tr key={item?.id || index}>
                                <td>
                                  {loading ? (
                                    <Placeholder xs={7} />
                                  ) : (
                                    item?.serialNumber
                                  )}
                                </td>
                                <td>

                                  {loading ? (
                                    <Placeholder xs={7} />
                                  ) : (

                                    <div className="d-flex align-items-center">
                                      <div className="avatar_width">
                                        <Avatar size="s" variant="name" className="me-1" >
                                          {item?.short_name?.slice(0, 2)}
                                        </Avatar>
                                      </div>
                                      <Link
                                        to="#"
                                        className="text-decoration-none text-black fw-semi-bold pe-none fs-8"
                                      >
                                        {item?.first_name} {item?.last_name}
                                      </Link>
                                    </div>


                                  )}
                                </td>

                                <td>
                                  {loading ? (
                                    <Placeholder xs={7} />
                                  ) : (
                                    null
                                  )}
                                </td>
                                <td className="">
                                  {loading ? (
                                    <Placeholder xs={7} />
                                  ) : (
                                    getStatusIconUser(item.status)
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </PhoenixDocCard>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>

        </Row>

      </AdvanceTableProvider>
      <ToastContainer />
    </div>
  );
};

export default AdminEntityListView;