import Constants from "common/Constants";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { Suspense, useEffect, useRef, useState, } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { ChangeEvent } from "react";
import { Button, Col, FloatingLabel, Form, Card, Row, Spinner, Table, } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';
import apiCall from "services/api";
import { dbDateToDate } from "common/timeStampToDate";
import calculateSerialNumber from "common/calculateSerialNumber";
import Avatar from "components/base/Avatar";
import CustomPagination from "components/sp-common/CustomPagination";
import UpdateProjectPermission from "./UpdateProjectPermission";
import { toast, ToastContainer } from 'react-toastify';
import SearchInput from "components/sp-common/SearchInput";
import { getAdminPageBreadCrumbs } from "services/getAdminPageBreadCrumbs";
import { handleCutomError } from "services/handleCutomError";

// Assuming you have a type for your data objects
interface Project {
  id: number;
  role_id: number;
  user_designation: number;
  role_in_project: string | null;
  description: string;
  last_action_datetime: number;
  is_user_watching: number;
  is_project_admin: number;
  is_story_admin: number;
  is_meeting_admin: number;
  is_diary_admin: number;
  status: number;
  serialNumber: number;
  project: ProjectDetail;

}

interface ProjectDetail {
  id: number;
  title: string;
  short_name: string | null;
  alias: string;
  description: string;
  parent_id: number | null;
  start_date: string;
  end_date: string | null;
  editable: number;
  category: number | null;
  phase: number | null;
  donar: string | null;
  entity_id: number;
  logo: string | null;
  etag: string | null;
  status: number;
}

interface User {
  id: number;
  username: string;
  short_name: string;
  color_code: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  is_external: number;
  is_super_admin: number;
  entity_id: number;
  designation_id: number;
  description: string | null;
  status: number;
  created_at: number;
}

// Define the type for the page filters state
type PageFiltersState = {
  status: string;
  titleSearch: string;
};

const AdminUserView = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("AdminUserView")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const { id } = useParams();
  // Now 'user' is accessible in your component
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [data, setData] = useState<Project[] | null>(null);
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 1,
    "pageSize": 0,
    "total_page": 1,
  });
  const [userData, setUserData] = useState<User | null>(null)
  const [pageIndex, setPageIndex] = useState<number | null>(1);
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [addStatus, setAddStatus] = useState<boolean>(false);

  const [userPermission, setUserPermission] = useState({
    id: "",
    user_id: "",
    role_in_project: "",
    is_project_admin: "",
    is_story_admin: "",
    is_meeting_admin: "",
    is_diary_admin: "",
    user_designation: "",
    alias: ""
  });
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    status: "1",
    titleSearch: ""
  })

  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, setBreadCrumb]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiCall({
          url: `admin/user/${id}/view?expand=project&page=${pageIndex}&status=${pageFilters.status}`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response.status === 200) {
          const updatedProjects = response?.data?.data?.permission.map((projects: any, index: any) => ({
            ...projects,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.data?.summary?.pageSize) // Assuming 10 items per page
          }));
          let summary = response?.data?.data?.summary;
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setUserData(response?.data?.user)
          setData(updatedProjects);
          setAddStatus(false)
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [userTkn, pageIndex, addStatus, pageFilters.status]);

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: data || [],
    columns: projectsTablecolumns,
    pageSize: 2,
    pagination: true,
    sortable: true,
  });

  const searchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: e.target.value
    }))
  };


  const handleSearchInputReq = (value: string) => {

    // You can use the titleSearch state value to filter your data
    const fetchData = async () => {
      let searchUrl = ""
      if (value === "search") {
        searchUrl = `admin/user/${id}/view?expand=project&page=${pageIndex}&status=${pageFilters?.status}&title=${pageFilters.titleSearch}`
      } else {
        searchUrl = `admin/user/${id}/view?expand=project&page=${pageIndex}&status=${pageFilters?.status}&title=`
      }

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          const updatedProjects = response?.data?.data?.permission.map((projects: any, index: any) => ({
            ...projects,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.data?.summary?.pageSize) // Assuming 10 items per page
          }));
          let summary = response?.data?.data?.summary;
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setData(updatedProjects);
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  const handleOnBlur = async (value: any) => {
    if (value !== "") {
      let searchUrl = ""

      searchUrl = `admin/user/${id}/view?expand=project&page=${pageIndex}&status=${pageFilters.status}`;

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          const updatedProjects = response?.data?.data?.permission.map((projects: any, index: any) => ({
            ...projects,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.data?.summary?.pageSize) // Assuming 10 items per page
          }));
          let summary = response?.data?.data?.summary;
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setUserData(response?.data?.user)
          setData(updatedProjects);
          setAddStatus(false)
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    }

  }

  useEffect(() => {
    // Now 'tableRef.current' contains the reference to the table element
    const table = tableRef.current;

    if (table) {
      const firstRow = table?.rows[1];
      const lastRowIndex = table?.rows.length - 1;
      const lastRow = table?.rows[lastRowIndex];
      if (firstRow) {
        const firstCell = firstRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueFirst(value)
        }
      }

      if (lastRow) {
        const firstCell = lastRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueLast(value)
        }
      }
    }
  }, [data]);

  const handlePageFilters = (value: string) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: "",
    }));
  }

  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">{userData?.first_name} {userData?.last_name}</span>{" "}
          </h2>
        </div>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Col xs={12} sm="auto">
            <div className="d-flex flex-wrap gap-3 ">
             
              <SearchInput
                pageFilters={pageFilters}
                handleSearchInputChange={searchInputOnChange}
                handleSearchInputBlur={handleSearchInputReq}
                handlePageFilters={handlePageFilters}
                handleOnBlur={handleOnBlur}
                title="Title"
              />
              <FloatingLabel controlId="lead-woner" label="Status">
                <Form.Select onChange={(e) => {
                  setPageFilters((prevData) => ({
                    ...prevData,
                    status: e.target.value
                  }))
                }}>
                  <option value="1">Ongoing</option>
                  <option value="2">Archive</option>
                  <option value="0">Deleted</option>
                </Form.Select>
              </FloatingLabel>


            </div>
          </Col>
        </div>
        <div className="d-md-block d-none">
          <PhoenixDocCard className="p-4 mt-3">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="light" />{" "}
              </div>
            ) :
              (
                <>
                  <Table
                    className="phoenix-table fs-9 project-table border rounded"
                    size="sm"
                    striped
                    hover
                    responsive
                    ref={tableRef}
                  >
                    <thead>
                      <tr id={"a1"}>
                        <th style={{ width: "2%", maxWidth: "20px", padding: "16px 0px" }}>#</th>
                        <th
                          className="px-1"
                          style={{ width: "55%", minWidth: "200px" }}
                        >
                          {Constants.project_title}
                        </th>
                        <th
                          className="px-1"
                          style={{ width: "15%", minWidth: "200px" }}
                        >
                          {Constants.created_date}
                        </th>
                        <th
                          className="px-1"
                          style={{ width: "15%", minWidth: "200px" }}
                        >
                          {Constants.status}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data?.map((item: Project, index: number) => {

                          return (
                            <tr key={item?.serialNumber}>
                              <td>
                                {item?.serialNumber}
                              </td>
                              <td>
                                <div className="d-flex  align-items-center text-decoration-none fw-semi-bold fs-8 mb-0 gap-2">
                                  <div className="avatar_width">
                                    {
                                      item?.project?.logo ?
                                        <Avatar src={item?.project?.logo} size="m" />
                                        :
                                        <Avatar size="m" variant="name" className="me-1">{item?.project?.short_name?.slice(0, 2)}</Avatar>
                                    }
                                  </div>
                                  {item?.project?.title.slice(0, 75)}
                                </div>
                              </td>
                              <td>
                                {dbDateToDate(item?.project?.start_date)}
                              </td>
                              <td >

                                <div className="d-flex gap-1">

                                  <Button
                                    variant="phoenix-primary"
                                    className="btn-icon rounded-1 bg-transparent"
                                    onClick={() => {

                                      setUserPermission({
                                        id: String(item.id),
                                        user_id: String(id),
                                        role_in_project: item?.role_in_project !== null ? item?.role_in_project : "",
                                        is_project_admin: String(item?.is_project_admin) || "",
                                        is_story_admin: String(item?.is_story_admin) || "",
                                        is_meeting_admin: String(item?.is_meeting_admin) || "",
                                        is_diary_admin: String(item?.is_diary_admin) || "",
                                        user_designation: String(item?.user_designation) || "",
                                        alias: String(item?.project?.alias) || ""
                                      });
                                      setOpenUpdateModal(true); // Optionally open the update modal
                                    }}
                                  >
                                    <Unicon icon={UilPen} size={20} />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
                    <Col className="d-flex fs-9">
                      <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                        {indexValueFirst} to {indexValueLast}
                        <span className="text-600"> items of </span>
                        {summary?.total}
                      </p>
                    </Col>
                    <Col xs="auto">
                      <CustomPagination
                        pageIndex={pageIndex}
                        totalPage={summary?.total_page}
                        activePage={Number(summary?.page)}
                        setPageIndex={setPageIndex}
                      />
                    </Col>
                  </Row>
                    :
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                      <span className="text-600"> No record found !!</span>
                    </p>
                  }
                </>
              )}
          </PhoenixDocCard>


        </div>

      </AdvanceTableProvider>
      <Suspense fallback={<div>Loading...</div>}>
        {openUpdateModal && (
          <UpdateProjectPermission
            show={openUpdateModal}
            handleClose={() => setOpenUpdateModal(false)}
            userPermission={userPermission}
            setAddStatus={setAddStatus}
            toast={toast}
            user_id={id}
            navigation={navigation}
          />
        )
        }
      </Suspense>
      <ToastContainer />
    </div>
  );
};

export default AdminUserView;
