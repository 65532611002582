import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  text: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, onHide, onConfirm, text }) => {
  return (
    <Modal show={show} onHide={onHide} className="p-0" >
      <Modal.Header className="border-200 p-4">
        <h5 className="modal-title text-1000 fs-7 lh-sm">Confirm</h5>
        <Button className="p-1" onClick={onHide} style={{background:"none", color:"#31374a"}}>
          <FontAwesomeIcon icon={faTimes} className="fs-9" />
        </Button>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="phoenix-primary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="phoenix-primary" onClick={onConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
