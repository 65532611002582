import Constants from "common/Constants";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState, Suspense } from "react";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import Unicon from 'components/base/Unicon';
import { UilPen, UilRefresh, UilTrash } from '@iconscout/react-unicons';
import { Button, Col, Nav, Tab, Row, Table, } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import Avatar from "components/base/Avatar";
import parse from 'html-react-parser';
import apiCall from "services/api";
import AddExistingUserModal from "./AddExistingUserModal";
import { useMaster } from "providers/MasterContext";
import { geCategoryNameById, geDesignationNameById,  getPhaseNameById } from "utils/getNames";
import UpdateUserPermissionModal from "./UpdateUserPermissionModal";
import { toast, ToastContainer } from 'react-toastify';
import { getAdminPageBreadCrumbs } from "services/getAdminPageBreadCrumbs";
import ConfirmationModal from "common/ConfirmModal";
import { Tooltip } from "react-tooltip";
import { handleCutomError } from "services/handleCutomError";

interface User {
  id: number;
  user_id: number;
  project_id: number;
  role_id: number;
  role_in_project: string;
  projectlogo: string;
  user_designation: number;
  description: string | null;
  last_action_datetime: number;
  is_user_watching: number;
  is_project_admin: number;
  is_story_admin: number;
  is_meeting_admin: number;
  is_diary_admin: number;
  status: number;
  user: {
    id: number;
    username: string;
    short_name: string;
    color_code: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string | null;
    is_external: number;
    is_super_admin: number;
    designation_id: number | null;
    description: string | null;
    status: number;
    is_user_super_admin: number;
    status_name: string;
    entity: {
      id: number;
      name: string;
      short_name: string | null;
      alias: string;
      status: number;
    } | null;
  };
}

type UserDel = {
  id: number;
  name: string;
}

const AdminProjectView = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("AdminProjectView")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace, alias } = useParams();
  const [projectData, setProjectData] = useState<any>()
  const [projectStatus, setProjectStatus] = useState<any>()

  const { projCategory, designation, phases } = useMaster();
  const { userTkn, workSpaceTkn, signOut } = useAuth();

  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [allUsers, setAllUsers] = useState([])
  const [presentUser, setPresentUser] = useState<User[]>([]);
  const [pastUser, setPastUser] = useState<User[]>([]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openAddExistingUserModal, setOpenAddExistingUserModal] = useState(false);
  const [addStatus, setAddStatus] = useState<boolean>(false);
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [userPermission, setUserPermission] = useState({
    id: "",
    user_id: "",
    role_in_project: "",
    is_project_admin: "",
    is_story_admin: "",
    is_meeting_admin: "",
    projectlogo: "",
    is_diary_admin: "",
    user_designation: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmedItemAlias, setConfirmedItemAlias] = useState<UserDel>();

  const { config: { theme, navbarPosition }, setConfig, } = useAppContext();

  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, setBreadCrumb]);

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = 'slim';
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });

  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await apiCall({
          url: `admin/project/${alias}/view`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });
        
        setProjectData(response?.data)
        setProjectStatus(response?.data?.status)

        const response2 = await apiCall({
          url: `admin/project/${alias}/permissions/list?expand=user`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response2.status === 200) {
          let permissionData = response2?.data?.data?.permission
          const presentUsers = permissionData.filter((user: User) => user.status === 1);
          const pastUsers = permissionData.filter((user: User) => user.status !== 1);
          const userIds: any = [];
          permissionData.forEach((item: any) => {
            userIds.push(item.user_id);
          });
          setAllUsers(userIds)
          setAddStatus(false)
          setPresentUser(presentUsers);
          setPastUser(pastUsers);

        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false)
      }
    };
    fetchData();
  }, [userTkn, addStatus]);

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: presentUser || pastUser || [],
    columns: projectsTablecolumns,
    pageSize: 2,
    pagination: true,
    sortable: true,
    // selection: true
  });

  const confirmDelete = async () => {
    // Call ChangeStatus function
    if (confirmedItemAlias) {
      await DeleteUser(confirmedItemAlias?.id, confirmedItemAlias?.name);
      setShowConfirmation(false);
    }

  };

  const DeleteUser = async (id: number, name: string) => {
    try {
      setLoading(true);
      const response = await apiCall({
        url: `admin/project/${alias}/permissions/${id}/delete`,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });


      if (response?.status === 200) {
        // Ensure that newData is always a valid array
        setAddStatus(true)
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  const projectActions = async (alias: any, action: string) => {
    let url = '';
    if (action === 'ongoing') {
      url = `admin/project/${alias}/ongoing`;
    }


    try {
      const response = await apiCall({
        url: url,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });
      if (response.status === 200) {

        toast.success(response?.data?.message, {
          position: "top-center"
        });

        if (action === 'ongoing') {
          setProjectStatus(response?.data?.project?.status)
        }
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false)
    }

  }

  return (
    <>
      <div >
        <PageBreadcrumb items={breadCrumb} navigation={navigation} />
        <AdvanceTableProvider {...table}>
          <div className="d-flex flex-wrap mb-4  justify-content-between">
            <h2 className="mb-0 d-flex align-items-center gap-1">
              <span className="me-3">{Constants.user_project_permissions}</span>
            </h2>
          </div>

          <div className="d-md-block d-block">
            <PhoenixDocCard className="p-4 mt-4 ">
              {
                !loading && <>
                  <Row className="d-flex">
                    <Col xs={10}>
                      <div className="d-flex gap-2 align-items-center">
                        <div className="avatar_width">
                          {
                            projectData?.projectlogo ?
                              <Avatar src={projectData?.projectlogo} size="l" />
                              :
                              <Avatar size="l" variant="name" className="me-1">{projectData?.short_name?.slice(0, 2)}</Avatar>
                          }


                        </div>

                        <Link to="#" className="text-decoration-none fw-bold text-black pe-none fs-7 font_sizeset">
                          {projectData?.title}
                        </Link>
                      </div>
                    </Col>

                    <Col xs={2}>
                      <div className="d-flex justify-content-end">
                        {Number(projectStatus) === 0 &&
                          <>
                            <Button
                              variant="phoenix-primary"
                              className="btn-icon rounded-1"
                              onClick={() => { projectActions(alias, 'ongoing') }}
                            >
                              <Unicon icon={UilRefresh} size={20} id="ongoing" />
                              <Tooltip anchorSelect="#ongoing" clickable>On-going</Tooltip>
                            </Button>
                          </>
                        }
                        {Number(projectStatus) !== 0 && <Link
                          to={{
                            pathname: `/${workspace}/admin/project/${alias}/update-project`,
                          }}>
                          <Button
                            variant="phoenix-primary"
                            className="btn-icon me-2 rounded-1 bg-transparent"
                            onClick={() => {

                            }}
                          >
                            <Unicon icon={UilPen} size={20} />
                          </Button>
                        </Link>
                        }
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex mt-1">
                    <div className="d-flex  text-700 fw-semi-bold mt-2 gap-15">
                      <div className="d-flex align-items-center">
                        <div className="fs-9 mt-2">
                          {"Category: "}
                          <span className="text-1100">
                            {projCategory && geCategoryNameById(Number(projectData?.category), projCategory)}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="fs-9 mt-2">
                          {"Phase: "}
                          <span className="text-1100">
                            {phases && getPhaseNameById(projectData?.phase, phases)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="d-flex mt-1">
                    <div className="d-flex  text-700 fw-semi-bold  gap-15">
                      <div className="d-flex align-items-center">
                        <div className="fs-9 mt-2 ">
                          {"Description: "}
                          <span className="text-1100">
                            {projectData?.description && parse(projectData?.description)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                </>
              }
            </PhoenixDocCard>

            <Row>
              <Col xl={12} xxl={12} className="mb-1 mt-4">
                <Tab.Container defaultActiveKey="presentuser">
                  <Nav variant="underline" className="">
                    <Nav.Item className="under_line">
                      <Nav.Link eventKey="presentuser">Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="under_line">
                      <Nav.Link eventKey="pastuser">Past User</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content style={{ marginTop: "-2px" }}>
                    <Tab.Pane eventKey="presentuser">
                      <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1'>
                        <Row className="justify-content-end mb-3">
                          <Col className="d-flex gap-3 justify-content-end">
                            {Number(projectStatus) !== 0 && <Link
                              className="btn btn-outline-primary px-4 "
                              to=""
                              onClick={() => setOpenAddExistingUserModal(true)}
                            >
                              {Constants.existing_user}
                            </Link>
                            }
                          </Col>

                        </Row>
                        <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive>
                          <thead>
                            <tr id={'a1'} >
                              <th style={{ width: '2%', maxWidth: '20px', padding: "16px 0px" }}>#</th>
                              <th className="px-1" style={{ width: '25%', minWidth: '200px' }}>{Constants.name}</th>
                              <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.email}</th>
                              <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.designation}</th>
                              {Number(projectStatus) !== 0 && <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.action}</th>}
                            </tr>
                          </thead>
                          {!loading && <tbody>
                            {presentUser?.length > 0 ?
                              <>
                                {presentUser && presentUser?.map((item: User, index: number) => (
                                  <tr key={index}>
                                    <td>{(index + 1)}</td>
                                    <td>
                                      <div className="d-flex align-items-center mb-2 order-2">
                                        <div className="avatar_width">
                                          <Avatar
                                            size="s"
                                            variant="name"
                                            className="me-1"
                                          >
                                            {item?.user?.short_name?.slice(0, 2)}
                                          </Avatar>
                                        </div>
                                        <div className="fw-bold mb-0 text-truncate lh-1">
                                          <div className="d-flex gap-2 align-items-center">
                                            <span className="fw-bold ms-1 fs-8 text-800">
                                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td> {item?.user?.email}</td>
                                    <td>
                                      {geDesignationNameById(Number(item?.user?.designation_id), designation)}
                                    </td>
                                    {Number(projectStatus) !== 0 && <td>
                                      <div className="d-flex gap-2  align-items-center">
                                        <Button
                                          variant="phoenix-primary"
                                          className="btn-icon rounded-1 bg-transparent"
                                          onClick={() => {

                                            setUserPermission({
                                              id: String(item.id),
                                              user_id: String(item?.user_id) || "",
                                              role_in_project: item?.role_in_project !== null ? item?.role_in_project : "",
                                              projectlogo: String(item?.projectlogo) || "",
                                              is_project_admin: String(item?.is_project_admin) || "",
                                              is_story_admin: String(item?.is_story_admin) || "",
                                              is_meeting_admin: String(item?.is_meeting_admin) || "",
                                              is_diary_admin: String(item?.is_diary_admin) || "",
                                              user_designation: String(item?.user.designation_id) || "",
                                            });
                                            setOpenUpdateModal(true); // Optionally open the update modal
                                          }}
                                        >
                                          <Unicon icon={UilPen} size={18} />
                                        </Button>
                                        <Button
                                          variant="phoenix-primary"
                                          className="btn-icon rounded-1 bg-transparent"
                                          onClick={() => { setConfirmedItemAlias({ id: item.id, name: `${item?.user?.first_name} ${item?.user?.last_name}` }); setShowConfirmation(true) }}
                                        >
                                          <Unicon icon={UilTrash} size={18} />
                                        </Button>

                                      </div>
                                    </td>
                                    }
                                  </tr>
                                ))}
                              </>
                              :
                              <tr>
                                <td></td>
                                <td>
                                  <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                                    <span className="text-600"> No record found !!</span>
                                  </p>
                                </td>

                              </tr>
                            }
                          </tbody>
                          }
                        </Table>

                      </PhoenixDocCard>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pastuser">
                      <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1' >

                        <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive>
                          <thead>
                            <tr id={'a2'} >
                              <th style={{ width: '2%', maxWidth: '20px', padding: "16px 0px" }}>#</th>
                              <th className="px-1" style={{ width: '25%', minWidth: '200px' }}>{Constants.name}</th>
                              <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.email}</th>
                              <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.entity}</th>
                              <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.designation}</th>
                              <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.action}</th>

                            </tr>
                          </thead>
                          {!loading && <tbody>
                            {pastUser?.length > 0 ?
                              <>
                                {pastUser && pastUser?.map((item: User, index: number) => (
                                  <tr key={index}>
                                    <td>{(index + 1)}</td>
                                    <td>
                                      <div className="d-flex align-items-center mb-2 order-2">
                                        <div className="avatar_width">
                                          <Avatar
                                            size="s"
                                            variant="name"
                                            className="me-1"
                                          >
                                            {item?.user?.short_name?.slice(0, 2)}
                                          </Avatar>
                                        </div>
                                        <div className="fw-bold mb-0 text-truncate lh-1">
                                          <div className="d-flex gap-2 align-items-center">
                                            <span className="fw-bold ms-1 fs-8 text-800">
                                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td> {item?.user?.email}</td>
                                    <td>
                                      {/* {getEntityNameById(Number(item?.user?.entity), entity)} */}
                                    </td>
                                    <td>
                                      {geDesignationNameById(Number(item?.user?.designation_id), designation)}
                                    </td>
                                    <td>
                                      <div className="d-flex gap-2  align-items-center">
                                        <Button
                                          variant="phoenix-primary"
                                          className="btn-icon rounded-1 bg-transparent"
                                        >
                                          <Unicon icon={UilPen} size={18} />
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </>
                              :
                              <tr>
                                <td></td>
                                <td>
                                  <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                                    <span className="text-600"> No record found !!</span>
                                  </p>
                                </td>

                              </tr>

                            }
                          </tbody>
                          }
                        </Table>
                      </PhoenixDocCard>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>

            </Row>

          </div>

        </AdvanceTableProvider>

        <Suspense fallback={<div>Loading...</div>}>
          {openAddExistingUserModal && (<AddExistingUserModal
            show={openAddExistingUserModal}
            handleClose={() => setOpenAddExistingUserModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            alias={alias}
            allUsers={allUsers}
            navigation={navigation}
          />
          )}


          {openUpdateModal && (
            <UpdateUserPermissionModal
              show={openUpdateModal}
              handleClose={() => setOpenUpdateModal(false)}
              userPermission={userPermission}
              setAddStatus={setAddStatus}
              toast={toast}
              alias={alias}
              allUsers={allUsers}
              navigation={navigation}
            />
          )
          }

          {showConfirmation && (<ConfirmationModal
            show={showConfirmation}
            onHide={() => setShowConfirmation(false)}
            onConfirm={confirmDelete}
            text={`Are you sure you want to delete ${confirmedItemAlias?.name} ?`}
          />
          )}
        </Suspense>
        <ToastContainer />
      </div>

    </>

  );
};

export default AdminProjectView;
