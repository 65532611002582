import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Offcanvas } from 'react-bootstrap';
import {
  faArrowsRotate,
  faPalette,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { useHelpPanelContext } from 'providers/HelpPannelProvider';
import { useEffect, useState } from 'react';

const pages = [
  {
    pageName: "ProjectListMain",
    pageTitle: "Project List",
    url: "/project-list",
    description: "Page displaying the list of projects.",
    faqQuestions: [
      "What information is displayed on the Project List page?",
      "How can I filter the projects on this page?"
    ]
  },
  {
    pageName: "ProjectActionPoint",
    pageTitle: "Action Item Page",
    url: "/action-point",
    description: "Page displaying action items.",
    faqQuestions: [
      "How can I view and manage action items on this page?",
      "Can I assign action items to other users?"
    ]
  }
];

interface Page {
  pageName: string;
  pageTitle: string;
  url: string;
  description: string;
  faqQuestions: string[];
}

const HelpPanel = () => {

  const { helpPanelConfig: { openHelpPanel, helpPage }, setHelpPanelConfig } = useHelpPanelContext()
  const [data, setData] = useState<Page | null>(null);
  const handleClose = () => {
    setHelpPanelConfig(prevConfig => ({
      ...prevConfig,
      openHelpPanel: !openHelpPanel
    }));
  };

  useEffect(() => {
    // Find the page object with a matching pageName
    const matchingPage = pages.find(page => page.pageName === helpPage);
    if (matchingPage) {
      // If a matching page is found, set its data
      setData(matchingPage);
    }
  }, [helpPage]);

  return (
    <Offcanvas
      className="settings-panel border-0"
      show={openHelpPanel}
      onHide={() => { handleClose() }}
      placement="end"
    >
      <Offcanvas.Header className="align-items-start border-bottom flex-column">
        <div className="pt-1 w-100 mb-0 d-flex justify-content-between align-items-start">
          <div>
            <h5 className="mb-2 me-2 lh-sm">
              {/* <FontAwesomeIcon icon={faPalette} className="me-2 fs-8" /> */}
              {data && data?.pageTitle}
            </h5>
            <p className="mb-0 fs-9">
              {data && data?.description}
            </p>
          </div>
          <button className="btn p-1 fw-bolder" onClick={() => { handleClose() }}>
            <FontAwesomeIcon icon={faTimes} className="fs-8" />
          </button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-card scrollbar">
        <ul>
          {data && data.faqQuestions.map((question, index) => (
            <li key={index}>{question}</li>
          ))}
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HelpPanel;
