import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import { Member } from 'data/members';
import { ProjectListData } from 'data/projectList/ProjectListData';

export const projectsTablecolumns: ColumnDef<any>[] = [
  {
    accessorKey: '#',
    header: '#',
    cell: ({ row: { original, index } }) => {
      return (
        <div>
          {(index + 1)}
        </div>

      );
    },
    meta: {
      headerProps: {
        style: { width: '5%', minWidth: '20px' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },

  {
    accessorKey: 'name',
    header: 'Title',
    cell: ({ row: { original } }) => {

     
      return (
        <Link
          to="#!"
          className=" d-flex align-items-center text-900 text-hover-1000"
        >
          {/* <Avatar src={avatar} size="m" /> */}
          <p className="mb-0 ms-3 text-1100 fw-semi-bold">{original.title}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Category',
    cell: ({ row: { original } }) => {
      const { category } = original;
      return (
        <Link to={`mailto:${category}`} className="fw-semi-bold">
          {category}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },
  {
    accessorKey: 'mobile',
    header: 'Phase',
    cell: ({ row: { original } }) => {
      const { mobile } = original;
      return (
        <Link to={`tel:${mobile}`} className="fw-bold text-1100">
          {mobile}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '200px' },
        className: 'pe-3'
      }
    }
  },
  {
    accessorKey: 'city',
    header: 'Client',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'lastActive',
    header: 'Last Active',
    meta: {
      headerProps: {
        style: { width: '21%', minWidth: '200px' },
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end text-700'
      }
    }
  },
  {
    accessorKey: 'joined',
    header: 'Editable',
    meta: {
      headerProps: {
        style: { width: '19%', minWidth: '200px' },
        className: 'text-end pe-0'
      },
      cellProps: {
        className: 'text-700 text-end'
      }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    meta: {
      headerProps: {
        style: { width: '19%', minWidth: '200px' },
        className: 'text-end pe-0'
      },
      cellProps: {
        className: 'text-700 text-end'
      }
    }
  }
];

const ProjectsTablesList = () => {
  return (
    <div>
      <AdvanceTable  tableProps={{ className: 'phoenix-table fs-9 project-table ', size:'sm', striped: true,hover:true, responsive: true}} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ProjectsTablesList;
