import { capitalize } from 'helpers/utils';
import { useEffect, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { RouteItems, routes } from 'sitemap';
import TopNavMegaMenu from './TopNavMegaMenu';
import TopNavItem from './TopNavItem';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';

const NavbarTopNav = () => {
  const { breakpoints } = useBreakpoints();
  const { pathname } = useLocation();

  return (
    <Nav className="navbar-nav-top pb-lg-0 scrollbar bg-white px-2">
      {routes.map((route) => {
        if (route.label !== 'Admin') {
          return (
            <NavbarTopNavItem route={route} key={route.label} pathname={pathname} breakpoints={breakpoints} />
          );
        }
        return null;
      })}
    </Nav>
  );
};

const NavbarTopNavItem = ({ route, pathname, breakpoints }: { route: RouteItems, pathname: string, breakpoints: any }) => {
  const Icon = route.icon;
  const path = route.path;
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false); // State to track active status
  const {workSpace} = useAuth()
  const navigation = useNavigate() as NavigateFunction;

  const handleMouseEnter = () => {
    if (breakpoints.up('lg')) {
      setShow(true);
    }
  };

  const handleMouseLeave = () => {
    if (breakpoints.up('lg')) {
      setShow(false);
    }
  };

  useEffect(() => {
   
    setIsActive(pathname === `/${workSpace?.alias}${path}`); // Check if current path matches this item's path
  }, [pathname, `${workSpace?.alias}${path}`]);

  const handleClick = () => {
      navigation(`${workSpace?.alias}${path}`);
  };

  return (
    <Dropdown
      as="li"
      show={show}
      className={`nav-item ${isActive ? 'active' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onToggle={() => setShow(!show)}
    >
      <Dropdown.Toggle
        as="a"
        variant=""
        className="nav-link lh-1 d-flex align-items-center cursor-pointer"
        onClick={handleClick}
      >
        <Icon className="me-2" size={16} />
        <span>{capitalize(route.horizontalNavLabel ? route.horizontalNavLabel : route.label)}</span>
      </Dropdown.Toggle>
      {(route.megaMenu) ? (<TopNavMegaMenu route={route} /> ) : (route.noDropDown) ? ("") :(  <TopNavItem route={route} /> )}
    </Dropdown>
  );
};

export default NavbarTopNav;
