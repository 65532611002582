import { lazy, Suspense, useState, useEffect, ChangeEvent, useRef } from "react";
import { LinkProps, Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import SearchInput from "components/sp-common/SearchInput";
import { Button, Col, Card, FloatingLabel, Form, Placeholder, Row, Table, } from "react-bootstrap";
import { useAuth } from "providers/AuthContext";
import Unicon from 'components/base/Unicon';
import { UilPen, UilTrash, UilCube, UilAbacus, UilUserCircle, UilEye, UilSetting, UilMap,UilExchange } from '@iconscout/react-unicons';
import Avatar from "components/base/Avatar";
import MeetingIcon from 'assets/img/icons/image-removebg2.png';
import GoogleMeetIcon from 'assets/img/icons/googlemeet.png';
const HomeSpider = () => {
  const navigation = useNavigate() as NavigateFunction;
  type PageFiltersState = {
    status: string;
    titleSearch: string;
  };
  const { userTkn, workSpaceTkn } = useAuth();
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 0,
    "pageSize": 0,
    "total_page": 0,
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    status: "1",
    titleSearch: ""
  })

  useEffect(()=>{
    if(!workSpaceTkn){
      navigation("/admin/all-workspace/list")
    }
  },[])

  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";
    
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const searchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: e.target.value
    }))
  };
  const handleSearchInputReq = (value: string) => {
    // Check if titleSearch length is 0

  };
  const handleOnBlur =(value:any) =>{
    
  }
  const handlePageFilters = (value: string) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: "",
    }));
  }
  return (
    <div>

      <div>
        <div className="d-flex flex-wrap mb-4 align-items-center justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">Dashboard </span>{" "}
          </h2>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <Col xs={12} sm="auto">
            <div className="d-flex flex-wrap gap-3 ">
              <SearchInput
                pageFilters={pageFilters}
                handleSearchInputChange={searchInputOnChange}
                handleSearchInputBlur={handleSearchInputReq}
                handlePageFilters={handlePageFilters}
                handleOnBlur={handleOnBlur}
                title="Title"
              />
              <div className="scrollbar overflow-hidden-y d-flex gap-2">
                <FloatingLabel controlId="lead-woner" label="Projects">
                  <Form.Select


                  >  <option value="1">Select</option>
                    <option value="1">Enable</option>
                    <option value="0">Disable</option>
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className="scrollbar overflow-hidden-y d-flex gap-2">
                <FloatingLabel controlId="lead-woner" label="Type">
                  <Form.Select


                  ><option value="1">Select</option>
                    <option value="1">Enable</option>
                    <option value="0">Disable</option>
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className="scrollbar overflow-hidden-y d-flex gap-2">
                <FloatingLabel controlId="lead-woner" label="Status">
                  <Form.Select


                  ><option value="1">Select</option>
                    <option value="1">Enable</option>
                    <option value="0">Disable</option>
                  </Form.Select>
                </FloatingLabel>
              </div>
            </div>
          </Col>

        </div>
      </div>


      <Row className="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
        <Col className="mb-3">
          <Card className="px-3 py-2 border-0">
            <div className="border-bottom-2 mb-2 top_headers">
              <div className="vervieww d-flex  align-items-center mb-3">
                <div className="avatar_width w-auto">
                  <Avatar size="m" variant="name" className="me-1">
                    GA
                  </Avatar>
                </div>
                <p className="fs-8 title_overview mb-0">Oxfam India</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center pb-1">
                <h4 className="fw-normal">Today Action Items</h4>
                <h3 className="fw-bold">03</h3>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3">Open</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Facebook Post Create 
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3">Open</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Instagram  Post 
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3">Open</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Social Media Post Create 
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col className="mb-3">
          <Card className="px-3 py-2 border-0">
            <div className="border-bottom-2 mb-2 top_headers">
              <div className="vervieww d-flex  align-items-center mb-3">
                <div className="avatar_width w-auto">
                  <Avatar size="m" variant="name" className="me-1">
                    OX
                  </Avatar>
                </div>
                <p className="fs-8 title_overview mb-0">Oxfam India</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center pb-1">
                <h4 className="fw-normal">Today Meetings</h4>
                <h3 className="fw-bold">03</h3>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
              <Row className="align-items-center border-bottom pb-2">
              <Col sm={2} className="col-2">
                <img src={MeetingIcon} alt="" />
              </Col>
              <Col sm={10} className="col-10">
                <p className="fs-8 fw-normal mb-1">Upcoming</p>
                <h4 className="fs-8 text-overflow-ellips"><span className="dots_red"></span>Meeting with Prashant and Sh</h4>
              </Col>
              </Row>
              <Row className="align-items-center justify-content-center py-3">
                <Col xs={4} >
                  <div className="Timesd">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
                <Col xs={2}>
                <Unicon icon={UilExchange} size={30}/>
                </Col>
                <Col xs={4}>
                <div className="Timesd float-end">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
              </Row>
              <Card>
                <Link to='' className="fs-8 text-muted text-decoration-none d-flex align-items-center justify-content-center py-1 gap-2">
                <img src={ GoogleMeetIcon} alt="" />
                Go to meet link
                   </Link>
              </Card>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
              <Row className="align-items-center border-bottom pb-2">
              <Col xs={2}>
                <img src={MeetingIcon} alt="" />
              </Col>
              <Col xs={10}>
                <p className="fs-8 fw-normal mb-1">Upcoming</p>
                <h4 className="fs-8 text-overflow-ellips"><span className="dots_red"></span>Meeting with PRS and Shrrr</h4>
              </Col>
              </Row>
              <Row className="align-items-center justify-content-center py-3">
                <Col xs={4}>
                  <div className="Timesd">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
                <Col xs={2}>
                <Unicon icon={UilExchange} size={30}/>
                </Col>
                <Col xs={4}>
                <div className="Timesd float-end">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
              </Row>
              <Card>
                <Link to='' className="fs-8 text-muted text-decoration-none d-flex align-items-center justify-content-center py-1 gap-2">
                <img src={ GoogleMeetIcon} alt="" />
                Go to meet link
                   </Link>
              </Card>
              </div>
            </div>
          </Card>
        </Col>
        <Col className="mb-3">
          <Card className="px-3 py-2 border-0">
            <div className="border-bottom-2 mb-2 top_headers">
              <div className="vervieww d-flex  align-items-center mb-3">
                <div className="avatar_width w-auto">
                  <Avatar size="m" variant="name" className="me-1">
                    GA
                  </Avatar>
                </div>
                <p className="fs-8 title_overview mb-0">Oxfam India</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center pb-1">
                <h4 className="fw-normal">Today Action Items</h4>
                <h3 className="fw-bold">02</h3>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3 text-danger">Pending</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Facebook Post Create 
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3 text-danger">Pending</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Instagram  Post 
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3 text-danger">Pending</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Social Media Post Create 
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </Col>   
        <Col className="mb-3">
          <Card className="px-3 py-2 border-0">
            <div className="border-bottom-2 mb-2 top_headers">
              <div className="vervieww d-flex  align-items-center mb-3">
                <div className="avatar_width w-auto">
                  <Avatar size="m" variant="name" className="me-1">
                    GA
                  </Avatar>
                </div>
                <p className="fs-8 title_overview mb-0">Oxfam India</p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center pb-1">
                <h4 className="fw-normal">Today Action Items</h4>
                <h3 className="fw-bold">03</h3>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3">Open</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Facebook Post Create 
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3">Open</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Instagram  Post 
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
                <h5 className="mb-3">Open</h5>
                <div className="vervieww d-flex  align-items-center">
                  <div className="avatar_width w-auto">
                    <Avatar size="s" variant="name" className="me-1">
                      GA
                    </Avatar>
                  </div>
                  <Link to="" className="text-decoration-none fw-semi-bold fs-8 ">
                  Social Media Post Create 
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </Col>   
        <Col className="mb-3">
          <Card className="px-3 py-2 border-0">
            <div className="border-bottom-2 mb-2 top_headers">
              <div className="vervieww d-flex  align-items-center mb-3">
                
                <p className="fs-8 title_overview mb-0">Today Meetings </p>
              </div>
              <div className="overviewNumbers d-flex justify-content-between align-items-center pb-1">
             
                <h3 className="fw-bold">03</h3>
                <div className="avatar_width w-auto">
                  <Avatar size="m" variant="name" className="me-1">
                    GA
                  </Avatar>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
              <Row className="align-items-center border-bottom pb-2">
              <Col xs={2}>
                <img src={MeetingIcon} alt="" />
              </Col>
              <Col xs={10}>
                <p className="fs-8 fw-normal mb-1">Upcoming</p>
                <h4 className="fs-8 text-overflow-ellips"><span className="dots_red"></span>Meeting with Prashant and Sh</h4>
              </Col>
              </Row>
              <Row className="align-items-center justify-content-center py-3">
                <Col xs={4}>
                  <div className="Timesd">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
                <Col xs={2}>
                <Unicon icon={UilExchange} size={30}/>
                </Col>
                <Col xs={4}>
                <div className="Timesd float-end">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
              </Row>
              <Card>
                <Link to='' className="fs-8 text-muted text-decoration-none d-flex align-items-center justify-content-center py-1 gap-2">
                <img src={ GoogleMeetIcon} alt="" />
                Go to meet link
                   </Link>
              </Card>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body py-2 px-3">
              <Row className="align-items-center border-bottom pb-2">
              <Col xs={2}>
                <img src={MeetingIcon} alt="" />
              </Col>
              <Col xs={10}>
                <p className="fs-8 fw-normal mb-1">Upcoming</p>
                <h4 className="fs-8 text-overflow-ellips"><span className="dots_red"></span>Meeting with PRS and Shrrr</h4>
              </Col>
              </Row>
              <Row className="align-items-center justify-content-center py-3">
                <Col xs={4}>
                  <div className="Timesd">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
                <Col xs={2}>
                <Unicon icon={UilExchange} size={30}/>
                </Col>
                <Col xs={4}>
                <div className="Timesd float-end">
                    <h4 className="mb-0 fw-semi-bold">8:45</h4>
                    <p className="mb-0 fs-8 fw-normal">AM</p>
                  </div>
                </Col>
              </Row>
              <Card>
                <Link to='' className="fs-8 text-muted text-decoration-none d-flex align-items-center justify-content-center py-1 gap-2">
                <img src={ GoogleMeetIcon} alt="" />
                Go to meet link
                   </Link>
              </Card>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomeSpider;