import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { Suspense, lazy, useEffect, useState, useRef } from "react";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { ChangeEvent } from "react";
import { Button, Col, FloatingLabel, Form,  Placeholder, Row, Table, Spinner } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import Unicon from 'components/base/Unicon';
import { UilPen, UilToggleOff, UilToggleOn, UilTrash } from '@iconscout/react-unicons';
import apiCall from "services/api";
import calculateSerialNumber from "common/calculateSerialNumber";
import CustomPagination from "components/sp-common/CustomPagination";
import SearchInput from "components/sp-common/SearchInput";
import ConfirmationModal from "common/ConfirmModal";
import { getAdminPageBreadCrumbs } from "services/getAdminPageBreadCrumbs";
import { toast, ToastContainer } from "react-toastify";
import { useMaster } from "providers/MasterContext";
import { handleCutomError } from "services/handleCutomError";
const AddProjectCategoryModal = lazy(() => import("./AddProjectCategoryModal"));
const UpdateProjectCategoryModal = lazy(() => import("./UpdateProjectCategoryModal"));

// Assuming you have a type for your data objects
type Category = {
  id: number;
  title: string | null;
  status: number;
  orderby: number;
  serialNumber: number;
};

type CategoryDelete = {
  id: number;
  title: string | null;
};
// Define the type for the page filters state
type PageFiltersState = {
  status: string;
  titleSearch: string;
};

const AdminProjectCategoryList = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const { refetchData } = useMaster()
  const [pageName, setPageName] = useState("AdminProjectCategoryList")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [data, setData] = useState<Category[] | null>(null);
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 1,
    "pageSize": 0,
    "total_page": 1,
  });
  const [pageIndex, setPageIndex] = useState<number | null>(1);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);

  const [categoryUpdateData, setCategoryUpdateData] = useState({
    id: 0,
    title: "",
    orderby: 0,
    status: 0,
  });
  const [addStatus, setAddStatus] = useState<boolean>(false);
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    status: "1",
    titleSearch: ""
  })

  const { config: { theme, navbarPosition }, setConfig } = useAppContext();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmedItemAlias, setConfirmedItemAlias] = useState<number | null>(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [itemId, setItemId] = useState<CategoryDelete>({
    id: 0,
    title: ""
  });;


  //check workspace 
  useEffect(() => {
    if (!workSpaceTkn) {
        navigation("/admin/all-workspace/list")
    }
},[])
  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, workspace]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true
      try {
        let url = `admin/master/project-category/list?page=${pageIndex}&status=${pageFilters.status}&title=${pageFilters.titleSearch}`
        const response = await apiCall({
          url: url,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response.status === 200) {
          const updatedList = response?.data?.MasterProjectCategory?.data.map((category: any, index: any) => ({
            ...category,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.MasterProjectCategory?.summary?.pageSize) // Assuming 10 items per page
          }));
          setAddStatus(false)
          setData(updatedList);
          let summary = response.data.MasterProjectCategory.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [addStatus, pageIndex, pageFilters.status]);

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: data || [],
    columns: projectsTablecolumns,
    pageSize: 2,
    pagination: true,
    sortable: true,
    // selection: true
  });

  const searchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: e.target.value
    }))
  };

  const handlePageFilters = (value: string) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: "",
    }));
  }

  const handleSearchInputReq = (value: string) => {
    // Fetch data based on the titleSearch value
    const fetchData = async () => {
      let searchUrl = ""
      if (value === "search") {
        searchUrl = `admin/master/project-category/list?page=${pageIndex}&title=${pageFilters.titleSearch}&status=${pageFilters.status}`
      } else {
        searchUrl = `admin/master/project-category/list?page=${pageIndex}&title=&status=${pageFilters.status}`
      }
      try {
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          const updatedList = response?.data?.MasterProjectCategory?.data?.map((category: any, index: any) => ({
            ...category,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.MasterProjectCategory?.summary?.pageSize) // Assuming 10 items per page
          }));
          setData(updatedList);
          let summary = response.data.MasterProjectCategory.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      }
    };
    fetchData();
  };
  const handleOnBlur = async (value: any) => {
    if (value !== "") {
      let searchUrl = ""

      searchUrl = `admin/master/project-category/list?page=${pageIndex}&status=${pageFilters.status}&title=${pageFilters.titleSearch}`;

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          const updatedList = response?.data?.MasterProjectCategory?.data?.map((category: any, index: any) => ({
            ...category,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.MasterProjectCategory?.summary?.pageSize) // Assuming 10 items per page
          }));
          setAddStatus(false)
          setData(updatedList);
          let summary = response.data.MasterProjectCategory.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }
  const ChangeStatus = async (id: number) => {
    try {
      setLoading(true);
      const response = await apiCall({
        url: `admin/master/project-category/${id}/changestatus`,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      if (response.status === 200) {
        // Ensure that newData is always a valid array
        const newData: Category[] =
          data?.map((item) => {
            if (item.id === response.data.MasterProjectCategory.id) {
              item.status = response.data.MasterProjectCategory.status;
            }

            return item;
          }) || [];

        setData(newData);
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };
  useEffect(() => {
    // Now 'tableRef.current' contains the reference to the table element
    const table = tableRef.current;

    if (table) {
      const firstRow = table?.rows[1];
      const lastRowIndex = table?.rows.length - 1;
      const lastRow = table?.rows[lastRowIndex];
      if (firstRow) {
        const firstCell = firstRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueFirst(value)
        }
      }

      if (lastRow) {
        const firstCell = lastRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueLast(value)
        }
      }
    }
  }, [data]);

  const getStatusIcon = (status: number, id: number) => {
    if (status === 1) return <Unicon icon={UilToggleOn} size={21} color="" fill="#8FC644" onClick={() => { setConfirmedItemAlias(id); setShowConfirmation(true); }} />
    else if (status === 0) return <Unicon icon={UilToggleOff} size={21} color="" fill="#6E7891" onClick={() => { setConfirmedItemAlias(id); setShowConfirmation(true); }} />
    else return <div></div>
  }

  const confirmStatusChange = async () => {
    // Call ChangeStatus function
    if (confirmedItemAlias) {
      await ChangeStatus(confirmedItemAlias);
      setShowConfirmation(false);
    }
  };

  const confirmDeleteCategory = async () => {
    // Call ChangeStatus function
    if (itemId.id) {
      await DeleteCategory(itemId.id);
      setDeleteConfirmation(false);
    }
  };
  const DeleteCategory = async (id: number) => {
    try {
      setLoading(true);

      const response = await apiCall({
        url: `admin/master/project-category/${id}/delete`,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      if (response.status === 200) {
        // Ensure that newData is always a valid array
        refetchData()
        setAddStatus(true)
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4  justify-content-between align-items-center">
          <h2 className="mb-0">
            <span className="me-3">{Constants.project_category}</span>{" "}
          </h2>
          <Button
            className="btn btn-phoenix-primary px-5"
            onClick={() => setOpenAddModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {Constants.new_category}
          </Button>
        </div>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <Col xs={12} sm="auto">
            <div className="d-flex flex-wrap gap-3 ">
              <SearchInput
                pageFilters={pageFilters}
                handleSearchInputChange={searchInputOnChange}
                handleSearchInputBlur={handleSearchInputReq}
                handlePageFilters={handlePageFilters}
                handleOnBlur={handleOnBlur}
                title="Title"
              />
              <div className="scrollbar overflow-hidden-y d-flex gap-2">
                <FloatingLabel controlId="lead-woner" label="Status">
                  <Form.Select
                    onChange={(e) => {
                      setPageFilters((prevData) => ({
                        ...prevData,
                        status: e.target.value
                      }))
                    }}
                    value={pageFilters.status}
                  >
                    <option value="1">Enable</option>
                    <option value="0">Disable</option>
                  </Form.Select>
                </FloatingLabel>
              </div>
            </div>
          </Col>
        </div>
        <div className="d-md-block">

          <PhoenixDocCard className="p-4 mt-3">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="light" />{" "}
              </div>
            ) : (
              <>
                <Table
                  className="phoenix-table fs-9 project-table border rounded"
                  size="sm"
                  striped
                  hover
                  responsive
                  ref={tableRef}
                >
                  <thead>
                    <tr id={"a1"}>
                      <th style={{ width: "2%", maxWidth: "20px", padding: "16px 0px" }}>#</th>
                      <th
                        className="px-1"
                        style={{ width: "55%", minWidth: "200px" }}
                      >
                        {Constants.project_category}
                      </th>
                      <th
                        className="px-1"
                        style={{ width: "15%", minWidth: "200px" }}
                      >
                        {Constants.status}
                      </th>
                      <th
                        className="px-1"
                        style={{ width: "15%", minWidth: "200px" }}
                      >
                        {Constants.action}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item: Category, index: number) => {
                        return (
                          <tr key={item?.id || index}>
                            <td>   {loading ? (
                              <Placeholder xs={7} />
                            ) : (
                              item.serialNumber
                            )}</td>
                            <td>
                              <a className="text-decoration-none fw-semi-bold fs-8 mb-0  disabled_link text-black">{`${item?.title}`}</a>
                            </td>
                            <td className="toggle_border">
                              {getStatusIcon(item?.status, item?.id)}
                            </td>
                            <td>
                              <div className="d-flex gap-1">
                                <Button
                                  variant="phoenix-primary"
                                  className="btn-icon rounded-1 bg-transparent"
                                  onClick={() => {
                                    setCategoryUpdateData({
                                      id: item?.id,
                                      title: item?.title || "",
                                      orderby: item?.orderby,
                                      status: item?.status,
                                    });
                                    setOpenUpdateModal(true); // Optionally open the update modal
                                  }}
                                >
                                  <Unicon icon={UilPen} size={18} />
                                </Button>
                                <Button
                                  variant="phoenix-primary"
                                  className="btn-icon rounded-1 bg-transparent"
                                  // onClick={() => {
                                  //   setCategoryUpdateData({
                                  //     id: item?.id,
                                  //     title: item?.title || "",
                                  //     orderby: item?.orderby,
                                  //     status: item?.status,
                                  //   });
                                  //   setOpenUpdateModal(true); // Optionally open the update modal
                                  // }}
                                  onClick={() => {
                                    setItemId((prevData) => ({
                                      ...prevData,
                                      id: item?.id || 0, // Make sure to provide a default value if `item?.id` is undefined
                                      title: item?.title || ""
                                    }));
                                    setDeleteConfirmation(true)
                                  }}
                                >
                                  <Unicon icon={UilTrash} size={18} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
                  <Col className="d-flex fs-9">
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                      {indexValueFirst} to {indexValueLast}
                      <span className="text-600"> items of </span>
                      {summary?.total}
                    </p>
                  </Col>
                  <Col xs="auto">
                    <CustomPagination
                      pageIndex={pageIndex}
                      totalPage={summary?.total_page}
                      activePage={Number(summary?.page)}
                      setPageIndex={setPageIndex}
                    />
                  </Col>
                </Row>
                  :
                  <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                    <span className="text-600"> No record found !!</span>
                  </p>
                }
              </>
            )}

          </PhoenixDocCard>

        </div>

      </AdvanceTableProvider>
      <Suspense fallback={<div>Loading...</div>}>
        {openAddModal && (
          <AddProjectCategoryModal
            show={openAddModal}
            handleClose={() => setOpenAddModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            navigation={navigation}
          />)}
        {
          openUpdateModal && (
            <UpdateProjectCategoryModal
              show={openUpdateModal}
              handleClose={() => setOpenUpdateModal(false)}
              categoryUpdateData={categoryUpdateData}
              setAddStatus={setAddStatus}
              toast={toast}
              navigation={navigation}
            />)
        }

        {showConfirmation && (<ConfirmationModal
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
          onConfirm={confirmStatusChange}
          text="Are you sure you want to change the status?"
        />
        )}

        {deleteConfirmation && (<ConfirmationModal
          show={deleteConfirmation}
          onHide={() => setDeleteConfirmation(false)}
          onConfirm={confirmDeleteCategory}
          text={`Are you sure you want to delete Project Category "${itemId.title}"?`}
        />
        )}


      </Suspense>
      <ToastContainer />
    </div>
  );
};

export default AdminProjectCategoryList;
