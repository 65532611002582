// Define your regular expressions

const titleRegex = /^[a-zA-Z\s]{3,75}$/;  //at least 3 alphabetic characters with space, Max 75
const titleRegex14 = /^[a-zA-Z\s]{3,14}$/;  //at least 3 alphabetic characters with space, Max 14
const titleRegex255 = /^[a-zA-Z0-9\s]{3,255}$/;  //at least 3 alphabetic characters with space, Max 75
const titleRegexNoSpace = /^[a-zA-Z]{3,75}$/;  //at least 3 alphabetic characters with space, Max 75
const emailRegex = /^[a-zA-Z][a-zA-Z0-9._+-]*@([a-zA-Z0-9-]+\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;  // check Email
const passwordRegex = /^[a-zA-Z].{5,13}$/  // start with alphabets and minimum 6 digit
const passwordRedex2 = /^[a-zA-Z]?.{5,13}$/  // with or without alphabets and minmumm 6 digit
const passwordRedex3 = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\[\]\-_])[A-Za-z\d!@#$%^&*()\[\]\-_]{6,14}$/ 
const titleAlphaNumeric = /^[a-zA-Z0-9 ]{3,75}$/;  // alphanumeric strings with a minimum length of 3 characters and a maximum length of 75 characters, including spaces
//Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character (!, @, #, $, %, ^, &, *, (, ), [, ], -, _), and be between 6 and 14 characters long.



export default {
    titleRegex: titleRegex,
    titleRegex14: titleRegex14,
    titleRegex255: titleRegex255,
    titleRegexNoSpace: titleRegexNoSpace,
    emailRegex: emailRegex,
    passwordRegex : passwordRegex,
    passwordRedex2 : passwordRedex2,
    passwordRedex3 : passwordRedex3,
    titleAlphaNumeric: titleAlphaNumeric
}