// MetaContext.tsx
import axios from 'axios';
import Constants from 'common/Constants';
import { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

// Define the context type
type MetaContextType = {
    roles: RolesItem[] | null;
    projectstatus: ProjectStatusItem[] | null;
};


//Define the PhasesItem interface



//Define the Roles interface
interface RolesItem {
    id: number;
    title: string;
    alias: string;
    status: number;
}

//Define the TagsItem interface

interface ProjectStatusItem {
    id: number;
    title: string;
}





//define the context type (tags)

// Create the context
const MetaContext = createContext<MetaContextType | undefined>(undefined);

// Define a helper function to use the context
export function useMeta() {
    const context = useContext(MetaContext);
    if (!context) {
        throw new Error('useMeta must be used within a MetaProvider');
    }
    return context;
}

// Define the MetaProvider component
type MetaProviderProps = {
    children: ReactNode;
};

export function MetaProvider({ children }: MetaProviderProps) {
    const { userTkn, isAuthenticated, workSpaceTkn } = useAuth();
    const [token, setToken] = useState<string | null>(userTkn);
    const [roles, setRoles] = useState<RolesItem[] | null>(null);   //meta
    const [projectstatus, setProjectStatus] = useState<ProjectStatusItem[] | null>(null);   //meta


    useEffect(() => {
        let accessToken: any = JSON.parse(localStorage.getItem('accessToken') || '{}');
        

        const fetchUserFromLocalStorage = async () => {
            try {

                // Or example of parallel requests using Promise.all
                const [response] = await Promise.all([
                    axios.get(`${Constants.BASE_URL}meta/all-meta/list`, {
                        headers: {
                            'x-api-key': 'web_qwertyuiop',
                            'x-access-token': accessToken,
                            'workspace': workSpaceTkn,
                            "Access-Control-Allow-Origin": "*"
                        },
                    }),

                ]);
                
                if (response?.status === 200) {
                    // Handle the response data if needed
                  
                    setRoles(response.data.roles)
                    setProjectStatus(response.data.Projectstatus)
                }
               
            } catch (error) {
                console.error('Error fetching user from localStorage:', error);
            }
        };

        // Run the effect when the user logs in or the page is refreshed
        if (isAuthenticated && workSpaceTkn) {
            fetchUserFromLocalStorage();
        }
    }, [isAuthenticated, workSpaceTkn]);

    return <MetaContext.Provider value={{ roles, projectstatus}}>
        {children}
    </MetaContext.Provider>;
}
